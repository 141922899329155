
import { Buttons } from 'Components/Atoms/Button/Button';
import UserContext from 'Contexts/User';
import axios from 'axios'
import { BearVsBullRow } from 'common/models/CoinTable';
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Image, Modal } from 'react-bootstrap';
import PAX from '../../../assets/logos/PAX.png';
import AppContext from 'Contexts/AppContext';
import { Link, useNavigate } from 'react-router-dom';
import { showToast } from 'App';
import { ToastType } from 'Contexts/Notification';
import { Any } from 'currency.js';
import Countdown from 'react-countdown';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../../firebase';
import moment from 'moment';


const Logo = ({ symbol }: BearVsBullRow) => {
    return (
        <Image
            src={process.env.PUBLIC_URL + `/images/logos/${symbol?.toUpperCase()}.svg`}
            style={{
                // margin: "0 auto",
                width: `${symbol !== "ETH" ? "40px" : "35px"}`,
                height: `${symbol !== "ETH" ? "40px" : "40px"}`,
            }}
            onError={(e) =>
                ((e.target as HTMLImageElement).src = "/images/no_logo.png")
            }
        />
    );
};

const WalletBalance = () => {
    const [pendingAmount, setPendingAmount] = useState({
        ETH: 0,
        BNB: 0,
        MATIC: 0,
        TON: 0,
        USDT: 0,
    })    

    let navigate = useNavigate();
    const { userInfo, user } = useContext(UserContext);    
    const [pendingPax, setPendingPax] = React.useState<any>();
    const [allPaxValue, setAllPaxValue] = React.useState<any>({
        pendingPaxTotal:0,
        pendingPaxDuringMiningPassTotal:0,
        pendingPaxWithoutMiningPassTotal:0,
    });
    const [walletPopup, setWalletPopup] = React.useState("");
    const [popupMessage, setPopupMessage] = React.useState("");
    const [modalShow, setModalShow] = React.useState(false);
    const { walletTab, setWalletTab ,setHistoryTab} = useContext(AppContext);
    const [PaxToUserOnUpdateAddress, setPaxToUserOnUpdateAddress] = useState<any>();
    const [apiAllreadyCall, setApiAllreadyCall] = useState<any>(false);
    const [allValuesGreaterThanOne, setAllValuesGreaterThanOne] = useState<any>(false);
    const [deactivateApi, setDeactivateApi] = useState<any>(false);

    const handleClose = () => setModalShow(false);
    const handleShow = () => setModalShow(true);

    useEffect(() => {
        if (user?.uid) {            
            ShowPendingAmount(user?.uid)        
        }
    }, [user?.uid,userInfo?.paxAddress?.address])

    const sortDataByTimestamp = (data: any) => {
        return data.sort((a: any, b: any) => {            
                if (a?.timestamp?._seconds !== b?.timestamp?._seconds) {
                    return a?.timestamp?._seconds - b?.timestamp?._seconds;
                }
                return a?.timestamp?._nanoseconds - b?.timestamp?._nanoseconds;            
        });
    };

    const deactivateMiningPass = httpsCallable(functions, "deactivateMiningPass");
    const ShowPendingAmount = (userId:any) => {
        axios.get(`/payment/getPendingPaymentbyUserId/${userId}`)
            .then(async (response) => {    
                const checkValues = (obj:any) => {
                    for (let key in obj) {
                      if (obj[key] <= 1) {
                        return false;
                      }
                    }
                    return true;
                  };
              
                  // Update the state with the result
                  setAllValuesGreaterThanOne(checkValues(response?.data?.data));            
                setPendingAmount(response.data.data)
            })
            .catch((error) => {
                console.log(error, "error")
            })
        }
        const ShowPendingPax =() => {
        axios.post("payment/getAllPendingPaxByUserId", {              
                userId: userInfo?.uid            
        }).then((res: any) => {
            console.log(res?.data?.data?.result,"resdata");
            //res?.data?.data?.result?.paxList[0].currentPaxValue
            if (res?.data && res?.data?.data?.result?.pendingPaxTotal >0 ) {
                
                const sortedData = sortDataByTimestamp(res?.data?.data?.result?.paxList);
                console.log(sortedData,"sortedDatasortedData")
                setAllPaxValue({
                    pendingPaxTotal: res?.data?.data?.result?.pendingPaxTotal,
                    pendingPaxDuringMiningPassTotal: res?.data?.data?.result?.pendingPaxDuringMiningPassTotal,
                    pendingPaxWithoutMiningPassTotal: res?.data?.data?.result?.pendingPaxWithoutMiningPassTotal,
               })
                // const timestamp = sortedData[0];
                const timestamp = sortedData.find((obj:any) => !obj?.receivedDuringMiningPass);
                console.log(timestamp,"timestamptimestamp")
                          const date = new Date(timestamp.timestamp._seconds * 1000);
                   const endTime = date.getTime() + (24 * 60 * 60 * 1000);
                // const endTime = date.getTime() + (5 * 61 * 1000);
                           setPendingPax({
                            ...timestamp,
                            formattedDate: date.toLocaleString(),
                            endTime: endTime,                            
                       });                                              
            } else {
                setPendingPax({
                    pendingPaxValue:0,
                })    
                setAllPaxValue({
                    pendingPaxTotal: res?.data?.data?.result?.pendingPaxTotal,
                    pendingPaxDuringMiningPassTotal: res?.data?.data?.result?.pendingPaxDuringMiningPassTotal,
                    pendingPaxWithoutMiningPassTotal: res?.data?.data?.result?.pendingPaxWithoutMiningPassTotal,
               })            
            }
        }).catch((err: any) => {
            console.log(err, "resultdata")
        })
    }

    useEffect(() => {
        if (userInfo?.uid) {
            ShowPendingPax();
        }
    }, [!userInfo?.isUserUpgraded,!userInfo?.miningPass?.isActive,userInfo?.uid , userInfo?.paxAddress?.address,walletTab])

    console.log(pendingAmount,"pendingAmount")
    
    const handleButtonClick = async (uid: any) => {        
        for (const coin in pendingAmount) {
            
            // @ts-ignore
            if (pendingAmount[coin] > 0) {
                try {
                    const data = {
                        userId: uid,
                        // @ts-ignore
                        totalAmount: pendingAmount[coin],
                        transactionType: "REFERAL",
                        paymentToken: coin,
                    }
                    
                    axios.post(`/payment/parent/collectPendingParentPayment`, {
                        ...data
                    })
                        .then(async (response) => {
                            // setPendingAmount(response.data.data)     
                            if (!response?.data?.status) {
                                // ShowPendingAmount(user?.uid)
                                if (response?.data?.message == "No Parent Payment Found") {
                                    showToast(response?.data?.message, ToastType.ERROR)
                                } else {
                                    setWalletPopup("error")
                                    setPopupMessage(response?.data.message)
                                    handleShow()
                                }

                            } else {
                                setWalletPopup("success")
                                ShowPendingAmount(user?.uid)
                                setPopupMessage(response?.data.message)
                                handleShow()
                            }
                            console.log(response?.data, response?.data?.status, response?.data?.message, "response?.data?.message ")
                        })
                        .catch((error) => {
                            console.log(error, "error")

                        })
                } catch (error) {
                    console.error(`Error fetching ${coin} value:`, error);
                }
            }
        }
    };

    const AfterCollect = (type: any) => {
        
        if (type == "error") { 
            handleClose()
            setPopupMessage("")
            setWalletPopup("")            
            setWalletTab("setting")
            navigate(`/profile/wallet`)
            // setHistoryTab("Receive Payment")
            // navigate(`/profile/history`)
        } else {
            handleClose()
            setPopupMessage("")
            setWalletPopup("")
            // setWalletTab("setting")
            setHistoryTab("Referral Payment")
            navigate(`/profile/history`)
        }
    }
    console.log(walletTab,"walletTab")

    const skipHandler = async (PaxToUserOnUpdateAddress: any) => { 
        if (!apiAllreadyCall) {
            setApiAllreadyCall(true)
            const mintPaxToAdminOnGiveUp = httpsCallable(
                functions,
                "mintPaxToAdminOnGiveUp"
            );
            const data = {
                    userId: userInfo?.uid,
                    isGiveUp: true,                
                uniquePaxId: PaxToUserOnUpdateAddress?.uniquePaxId || PaxToUserOnUpdateAddress?.id,
            };
            await mintPaxToAdminOnGiveUp(data)
                .then((res) => {
                    console.log(res.data, "mintPaxToAdminOnGiveUp");
                    ShowPendingPax();
                    setApiAllreadyCall(false);
                })
                .catch((error) => {
                    ShowPendingPax();
                    console.log(error, "mintPaxToAdminOnGiveUp");
                    setApiAllreadyCall(false);
                });
        }
    }
    {console.log("pendingPaxTotal",allPaxValue,pendingPax)}
    return (
        <div className='d-flex justify-content-center align-items-center'
        >
            <div className='mt-3'>
                {!userInfo?.isUserUpgraded && !(userInfo?.miningPass?.expiryDate.seconds > moment().unix())  && <>
                <p className='mx-3 text-white' style={{
                    fontSize: "16px"
                }}>{"YOUR REWARD PENDING  AMOUNT".toLocaleUpperCase()}</p>
                
                    <div className='d-flex justify-content-around align-items-center my-2'
                        style={{
                            background: "white",
                            borderRadius: "5px",
                            padding: "8px 8px",
                        }}
                    >
                        <div className='d-flex align-items-center'>
                            <img src={PAX} alt=""
                                style={{
                                    // margin: "0 auto",
                                    width: "40px",
                                    height: "40px",
                                }}
                            />
                            <p className=''
                                style={{
                                    marginLeft: `${"23px"}`
                                }}
                            >PAX</p>
                        </div>                      
                        <p className=''
                            style={{
                                color: "#6352E8",
                            }}
                        
                        >
                            {allPaxValue?.pendingPaxDuringMiningPassTotal > 0 ?allPaxValue?.pendingPaxWithoutMiningPassTotal :allPaxValue?.pendingPaxTotal  || 0}
                        </p>
                        
                    </div>                    
                    {allPaxValue?.pendingPaxWithoutMiningPassTotal > 0 && <strong>
                        
                        <Countdown
                            date={pendingPax?.endTime}
                            key={pendingPax?.endTime}
                            renderer={({ hours, minutes, seconds, completed }) => {
                                if (completed) {
                                    // Return null to hide the text and time when the countdown is completed
                                    skipHandler(pendingPax);
                                    console.log("hello i am calling again and agian")
                                    return null;
                                }
                                return (
                                    <p className='mx-3' style={{ color:"black",fontSize: "15px", textTransform: "capitalize", textAlign: "center",fontWeight:"600" ,lineHeight:"25px" }}> <Link to="/upgrade" className='' style={{color:"black"}}>Upgrade</Link> your account to claim {pendingPax?.currentPaxValue}  PAX <br />before they expire in :{'  '}                                    
                                        {hours < 1 ? null : `${hours}:`}
                                        {minutes < 10 ? `0${minutes}` : minutes}
                                        :{seconds < 10 ? `0${seconds}` : seconds}                                    
                                    </p>
                                );
                            }}
                        />                        
                    </strong>}

                    {allPaxValue?.pendingPaxDuringMiningPassTotal > 0 && 
                    <>
                    <div className='d-flex justify-content-around align-items-center my-2'
                        style={{
                            background: "white",
                            borderRadius: "5px",
                            padding: "8px 8px",
                        }}
                    >
                        <div className='d-flex align-items-center'>
                            <img src={PAX} alt=""
                                style={{
                                    // margin: "0 auto",
                                    width: "40px",
                                    height: "40px",
                                }}
                            />
                            <p className='text-center'
                                style={{
                                    marginLeft: `${"23px"}`
                                }}
                            >MINING <br />
                            PAX</p>
                        </div>                      
                        <p className=''
                            style={{
                                color: "#6352E8",
                            }}
                        
                        >
                            {allPaxValue?.pendingPaxDuringMiningPassTotal}
                        </p>
                        
                    </div>
                    <strong>                                                          
                        <p className='mx-3 ' style={{ color:"black",fontSize: "15px", textTransform: "capitalize", textAlign: "center", width: `320px`,fontWeight:"600" ,lineHeight:"25px" }}> <Link to="/profile/wallet" onClick={() => {setWalletTab("setting")}} className='' style={{color:"black"}}>Add your PAX address</Link> <span> to claim your pending {PaxToUserOnUpdateAddress?.currentPaxValue} PAX. </span>
                        </p>
                    </strong>                    
                    </>
                    }
                </>}
                {/* {userInfo?.miningPass?.isActive && (
                            <>
                            <Countdown
                                date={new Date(userInfo?.miningPass?.expiryDate.seconds * 1000 + userInfo?.miningPass?.expiryDate.nanoseconds / 1000000)} 
                                renderer={({ hours, minutes, seconds,completed}) => {
                                    if (completed) {
                                        // Return null to hide the text and time when the countdown is completed                                        
                                        if(deactivateApi ==false) {
                                            setDeactivateApi(true)
                                            deactivateMiningPass({userId: userInfo?.uid,}).then((result) => {
                                                setDeactivateApi(false)
                                            }).catch((err) => {
                                                setDeactivateApi(false)                                                
                                            });;
                                        }
                                        return null
                                      }
                                    return (                            
                            <>
                            </>
                                    );
                                }}
                                />
                            </>
                )
                }  */}
                {(userInfo?.isUserUpgraded || (userInfo?.miningPass?.expiryDate.seconds > moment().unix()))  && !userInfo?.paxAddress?.address && (                   
                    <>
                    <p className='mx-3 text-white' style={{
                    fontSize: "16px"
                }}>{"YOUR REWARD PENDING  AMOUNT".toLocaleUpperCase()}</p>
                
                    <div className='d-flex justify-content-around align-items-center my-2'
                        style={{
                            background: "white",
                            borderRadius: "5px",
                            padding: "8px 8px",
                        }}
                    >
                        <div className='d-flex align-items-center'>
                            <img src={PAX} alt=""
                                style={{
                                    // margin: "0 auto",
                                    width: "40px",
                                    height: "40px",
                                }}
                            />
                            <p className=''
                                style={{
                                    marginLeft: `${"23px"}`
                                }}
                            >PAX</p>
                        </div>                      
                        <p className=''
                            style={{
                                color: "#6352E8",
                            }}
                        
                        >{allPaxValue?.pendingPaxTotal || 0}</p>
                        
                    </div>
                        {pendingPax?.pendingPaxValue > 0 && (<strong>
                            
                                <Countdown
                                date={pendingPax?.endTime} 
                                renderer={({ hours, minutes, seconds,completed}) => {
                                    if (completed) {
                                        // Return null to hide the text and time when the countdown is completed
                                        skipHandler(pendingPax);
                                        return null
                                      }
                                    return (
                                        <p className='mx-3 ' style={{ color:"black",fontSize: "15px", textTransform: "capitalize", textAlign: "center", width: `320px`,fontWeight:"600" ,lineHeight:"25px" }}> <Link to="/profile/wallet" onClick={() => {setWalletTab("setting")}} className='' style={{color:"black"}}>Add your PAX address</Link> <span> to claim your pending {PaxToUserOnUpdateAddress?.currentPaxValue} PAX. 
                                        {/* Before they expire in :{'  '}
                                        {hours < 1 ? null : `${hours}:`}
                                        {minutes < 10 ? `0${minutes}` : minutes}
                                            :{seconds < 10 ? `0${seconds}` : seconds} */}
                                        </span>
                            </p>

                                    );
                                }}
                                />
                        </strong>
                            )}                               
                    </>
                )}
                <p className='mx-3 text-white mb-0' style={{
                    fontSize: "16px",
                    margin:"20px 0px 10px 0px"
                }}>{"Your Referral Pending Amount".toLocaleUpperCase()}</p>
                {Object.keys(pendingAmount).length ?
                    <div className='d-flex flex-column'>
                        {Object.keys(pendingAmount)?.map((value, index) => {
                            if (value == "ETH" || value == "BNB" || value == "MATIC" || value == "USDT" || value == "TON") {
                                return <>

                                    <div className='d-flex justify-content-around align-items-center my-2 '
                                        style={{
                                            background: "white",
                                            borderRadius: "5px",
                                            padding:"8px",
                                    }}
                                    >
                                        <div className='d-flex align-items-center'>
                                            <Logo
                                                symbol={value}
                                                
                                            />                                            
                                            <p className=''
                                                style={{
                                                    marginLeft: `${value == "ETH" ? "23px" : "20px"}`
                                                }}
                                            >{value == "MATIC" ? "POL" : value} </p>
                                        </div>
                                        
                                        {/* @ts-ignore */}
                                        <p className=''
                                            style={{
                                                color:"#6352E8",
                                        }}
                                        >{pendingAmount[value] !=0 ? Number(pendingAmount[value]).toFixed(4):0}</p>
                                    </div>
                                </>
                        }
                            
                        })
                        }
                    </div>
                    :
                    <div className="d-flex justify-content-center" >
                        <p className='mt-3'>Pending Payment Amount is not found</p>
                    </div>
                }                                
                <div className={`${window.screen.width > 767 ? "justify-content-center" : "justify-content-center"} d-flex mt-3`}>

                    <Buttons.Primary
                          disabled={!userInfo?.uid || allValuesGreaterThanOne}
                        type='button' style={{
                            maxWidth: '200px',
                            marginLeft: `${window.screen.width > 767 ? "25px" : ""}`,
                            // opacity: `${getPendingShow ? 0.8 : 1}`
                        }}
                        // disabled={!userInfo?.uid}
                        onClick={() => {
                            handleButtonClick(userInfo?.uid)
                        }}
                    >
                        {/* {getPendingShow ? <span className=''> Pay me now...</span> : ' Pay me now'} */}
                        {"COLLECT NOW".toLocaleUpperCase()}
                    </Buttons.Primary>
                </div>
            </div>

            <div className="pb-3">                
                {/* {walletPopup && <WalletPopup tryAgainAction={CheckTransaction} startAgainAction={AddAddress} message={popupMessage} type={walletPopup} />} */}

            </div>
            <div>
                <Modal
                    show={
                        modalShow
                    } onHide={handleClose}
                    // size="sm"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <div className="d-flex justify-content-end">
                        <button type="button" className="btn-close " aria-label="Close" onClick={() => {
                            handleClose()
                        }}></button>
                    </div>
                    <Modal.Body>                                                                       
                        <div className='mt-3 text-center'>
                            <p>{walletPopup == "error" ? popupMessage : popupMessage + ", you can check your referral amount status in payment history" }</p>
                        </div>
                    </Modal.Body >
                    {/* <Modal.Footer> */}
                    < div className="d-flex justify-content-center " >
                        {walletPopup == "error"
                        ?<button type="button"
                            style={{
                                display: 'inline-block',
                                backgroundColor: '#dd3333',
                                border: '0',
                                borderRadius: '0.25em',
                                color: 'white',
                                fontSize: '1em',
                                margin: '.3125em',
                                padding: '.625em 1.1em',
                                transition: 'box-shadow .1s',
                                boxShadow: '0 0 0 3px rgba(0,0,0,0)',
                                fontWeight: '500',
                            }}
                                onClick={() => { AfterCollect(walletPopup) }}
                            >Add Address</button> : <Buttons.Primary className="mx-2" onClick={() => { AfterCollect(walletPopup) }}>{"Check Transaction"}</Buttons.Primary>}
                    </div >
                </Modal>
            </div >
        </div>
    )
}

export default WalletBalance