import { Container } from "react-bootstrap";
import React, {
  RefObject,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Direction,
  useCanVote,
  voteConverter,
  VoteResultProps,
} from "../../common/models/Vote";
import UserContext from "../../Contexts/User";
import { addDoc, collection, doc, setDoc, updateDoc } from "firebase/firestore";
import { db, functions } from "../../firebase";
import { Coin } from "../../common/models/Coin";
import AppContext from "../../Contexts/AppContext";
import VoteForm from "../VoteForm";
import { useTranslation } from "../../common/models/Dictionary";
import Bear from "../icons/Bear";
import Bull from "../icons/Bull";
import NotificationContext, { ToastType } from "../../Contexts/Notification";
import { voteProcedure } from "../Pairs/utils";
import { UserProps } from "../../common/models/User";
// import { timeStamp } from "console";
import { cmpRangeCoin } from "../Profile/utils";
import VoteRules from "Components/Admin/VoteRules";
import { firestore } from "../../firebase";
import { fetchCurrentTime, getGMTTimestamp } from "common/utils/helper";
import styled from "styled-components";
import moment from "moment";
import { httpsCallable } from "firebase/functions";

export const directions = {
  [Direction.BEAR]: { direction: "rise", name: "BEAR" },
  [Direction.BULL]: { direction: "fall", name: "BULL" },
};

export const VS = styled.div`
  flex-grow: 0;
  flex-basis: 10px;
  min-width: 0;
  color: red;
  left: 0;
  font-family: var(--font-family-poppins);
  font-weight: 700;
  color: var(--blue-violet);
  font-size: var(--font-size-l);
  letter-spacing: 0;
  line-height: 0px;
  white-space: nowrap;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 40px;
    left: 9px;
    width: 0;
    height: 30px;
    border: 1px solid var(--blue-violet);
    opacity: 1;
  }

  &::after {
    content: "";
    position: absolute;
    width: 8px;
    height: 0;
    border: 1px solid var(--blue-violet);
    opacity: 1;
    bottom: flex-end;
    top: 40px;
    bottom: -2px;
    left: 6px;
  }
`;

const CoinsForm = ({
  coin,
  setVoteId,
  setLoading,
  sound,
  setConfetti,
  selectedTimeFrame,
  setSelectedTimeFrame,
  selectedTimeFrameArray,
  cssDegree,
  votePrice,
  votedDetails,
  coinUpdated,
  hideButton,
  setHideButton,
  setpopUpOpen,
  propVote,
  setClickEnable,
}: {
  coin: Coin;
  setVoteId: (id: string) => void;
  setLoading: (bool: boolean) => void;
  setConfetti: (bool: boolean) => void;
  sound: RefObject<HTMLAudioElement>;
  selectedTimeFrame?: number;
  setSelectedTimeFrame?: (n: number) => void;
  selectedTimeFrameArray: any;
  cssDegree?: any;
  votePrice?: any;
  votedDetails?: any;
  coinUpdated: any;
  hideButton?: any;
  setHideButton: (value: number[]) => void;
  setpopUpOpen: React.Dispatch<SetStateAction<boolean>>;
  propVote: VoteResultProps;
  setClickEnable?: any;
}) => {
  const { votesLast24Hours, user, userInfo } = useContext(UserContext);
  const { setOneVoteOnly, oneVoteOnly, boosterVote, getSpinData,freeSpinData ,setHeaderCpmStart,goldenValue} = useContext(AppContext);
  const { showToast } = useContext(NotificationContext);
  const translate = useTranslation();
  const [canVote, tooltipText] = useCanVote();
  const {
    timeframes,
    voteRules: { maxVotes, timeLimit },
  } = useContext(AppContext);
  var urlName = window.location.pathname.split("/");
  const pageTrue = urlName.includes("pairs") || urlName.includes("coins");
  // console.log(timeframes,"timeframes")
  // const [selectedTimeFrame, setSelectedTimeFrame] = useState<number>();
  const [selectedOption, setSelectedOption] = useState<number>();
  const id = "BullVsBearForm";

  // console.log()
  const processGoldenVote = httpsCallable(functions, "processGoldenVote");
  useEffect(() => {
    window.scrollTo(0, 0);

    return window.scrollTo(0, 0);
  }, []);
  // chosenTimeframe?.name=="Gold"?goldenValue?.expirationTime:
  console.log(goldenValue?.expirationTime?.seconds,"chosenTimeframe?.name==goldenValue?.expirationTime:")
  const vote = useCallback(async () => {    
    if (!(selectedOption !== undefined && selectedTimeFrame !== undefined)) {
      return;
    }
    console.log(timeframes[selectedTimeFrame],"checkalldataforvote give vote");
    const getUTCTime= await getGMTTimestamp()
    const chosenTimeframe = timeframes[selectedTimeFrame];
    try {
      setLoading(true);
      if (!user?.uid) {
        throw new Error("Attention! You must be signed-in to cast your vote!");
      }
      console.log(urlName, "after vote i am calling 3");
      // const getCurrentTime = await fetchCurrentTime()
      const ref = await addDoc<VoteResultProps>(
        collection(db, "votes").withConverter(voteConverter),
        {
          coin: coin?.symbol,
          // @ts-ignore
          CPMRangePercentage: cmpRangeCoin[chosenTimeframe?.index] || 10,
          direction: selectedOption,
          status: userInfo?.status,
          timeframe: timeframes && chosenTimeframe,
          isGoldenVote:chosenTimeframe?.name=="Gold"?true:false,
          userId: user?.uid,
          voteTime: moment().valueOf(),
          voteSelected: "COIN",
          multiplier:chosenTimeframe?.name=="Gold" ?1:boosterVote || 1,
          // @ts-ignore
          valueVotingTime:
            coinUpdated[coin?.symbol]?.symbol == "BTC" ||
            coinUpdated[coin?.symbol]?.symbol == "ETH"
              ? coinUpdated[coin?.symbol]?.price || 0
              : `${coinUpdated[coin?.symbol]?.price || 0}${
                  coinUpdated[coin?.symbol]?.randomDecimal || 0
                }`,
          expiration:chosenTimeframe?.name=="Gold" ?getUTCTime:(moment().valueOf()) + chosenTimeframe.seconds * 1000,
          // expiration:(moment().valueOf()) + chosenTimeframe?.seconds * 1000,
          voteId:
            `${coin.symbol}-` +
            `${userInfo?.uid?.slice(0, 5)}` +
            `${moment().valueOf()}`,
          // @ts-ignore
          voteType: `${userInfo?.voteValue > 0 ? "FreeVote" : "ExtraVote"}`,
        } as VoteResultProps
      );     
      const voteId= `${coin.symbol}-`+`${userInfo?.uid?.slice(0, 5)}` +`${moment().valueOf()}`;
       
      afterUpdateVote(ref,voteId,chosenTimeframe)
      setHeaderCpmStart(false)
      const updateExtravote =
        !!user && votesLast24Hours.length < Number(maxVotes);
      if (!updateExtravote) {        
      }
      if (user?.uid) {
        setVoteId(ref.id);
      }
    } catch (e) {
      console.log(e);
      showToast((e as Error).message, ToastType.ERROR);
      setLoading(false);
    }
  }, [
    userInfo?.status,
    user?.uid,
    setVoteId,
    selectedOption,
    selectedTimeFrame,
    setLoading,
    coin.symbol,
    timeframes,
    showToast,
  ]);


  const afterUpdateVote = (ref: any,voteId?:any,chosenTimeframe?:any) => {
    const boosterVoteValue =chosenTimeframe?.name=="Gold"?1:boosterVote
    console.log(ref?.id,"getrefdata")
    if (ref?.id) {
      if(chosenTimeframe?.name=="Gold"){
        const data={
          voteId:`${ref?.id}`,
          userId:`${userInfo?.uid}`,
        }      
        processGoldenVote(data).then((result) => {
          console.log(result,"result")
        }).catch((err) => {
          console.log(err,"err")
        });
      }

      if (boosterVoteValue == 1) {           
        if (userInfo?.voteValue > 0) {
          // @ts-ignore
          const userDocRef = doc(firestore, "users", user?.uid);
          try {
            setDoc(
              userDocRef,
              { voteValue: userInfo?.voteValue - 1 },
              { merge: true }
            );
            if (userInfo?.voteValue - 1 == 0 && userInfo?.uid
            ) { 
              setTimeout(() => {                
                getSpinData(userInfo?.uid)              
              }, 1000);
            }
            setOneVoteOnly(false);
            console.log("User data updated successfully!");                       
          } catch (error) {
            console.error("Error updating user data:", error);
          }
        }
        // @ts-ignore
        if (userInfo?.rewardStatistics?.extraVote > 0 && userInfo?.voteValue == 0) {
          const rewardData = userInfo?.rewardStatistics;
          // @ts-ignore
          rewardData.extraVote = userInfo?.rewardStatistics?.extraVote - 1;
          console.log(rewardData, "allrewardData");
          // @ts-ignore
          const userDocRef = doc(firestore, "users", user?.uid);
          try {
            setDoc(
              userDocRef,
              { rewardStatistics: rewardData },
              { merge: true }
            );
            setOneVoteOnly(false);
            console.log("User data updated successfully!");            
          } catch (error) {
            console.error("Error updating user data:", error);
          }
        }
      } else if (userInfo?.voteValue > boosterVoteValue) {
        
          // @ts-ignore
          const userDocRef = doc(firestore, "users", user?.uid);
          try {
            setDoc(
              userDocRef,
              { voteValue: userInfo?.voteValue - boosterVoteValue},
              { merge: true }
            );
            if (freeSpinData.totalSpinsUsed < 4 && userInfo?.uid
            ) {          
              setTimeout(() => {                
                getSpinData(userInfo?.uid)              
              }, 1000);
            }
            setOneVoteOnly(false);            
            console.log("User data updated successfully!");
          } catch (error) {
            console.error("Error updating user data:", error);
          }               
      } else {
        const RealVote = boosterVoteValue - userInfo?.voteValue
        // const extraVoteValue = boosterVote - RealVote         
        const rewardData = userInfo?.rewardStatistics;
        // @ts-ignore
        rewardData.extraVote = userInfo?.rewardStatistics?.extraVote - RealVote;
        // @ts-ignore
        const userDocRef = doc(firestore, "users", user?.uid);
        try {
          setDoc(
            userDocRef,
            { voteValue: userInfo?.voteValue - userInfo?.voteValue, rewardStatistics: rewardData }, 
            { merge: true }
          );
          if (freeSpinData.totalSpinsUsed < 4 && userInfo?.uid
          ) { 
            console.log("yes i am freespin data")
            setTimeout(() => {                
              getSpinData(userInfo?.uid)              
            }, 1000);
          }
          setOneVoteOnly(false);          
          console.log("User data updated successfully!");
        } catch (error) {
          console.error("Error updating user data:", error);
        } 
      }
    }
  }

  const disabled = useMemo(
    () =>
      selectedTimeFrame === undefined ||
      !(
        (!propVote.expiration && propVote.success === undefined) ||
        (propVote.expiration && propVote.success !== undefined) ||
        moment().valueOf() >= propVote?.expiration
      ),
    [selectedTimeFrame, canVote]
  );

  const throttled_vote = useMemo(
    () => voteProcedure({ vote, sound, setConfetti, urlName }),
    [vote, sound, setConfetti]
  );
  // const throttled_vote = useCallback(
  //   async () => {
  //     console.log('throttled_vote called with:', urlName);
  //     return await vote();
  //   },
  //   [vote, sound, setConfetti, urlName]
  // );
  const [disableVoteButton, setDisableVoteButton] = useState(false);

  return (
    <Container className="p-0 ">
      {/* @ts-ignore */}
      <VoteForm
        {...{
          hideButton,
          setHideButton,
          disabled,
          selectedTimeFrame,
          setSelectedTimeFrame,
          selectedOption,
          setSelectedOption,
          id,
          canVote,
          selectedTimeFrameArray,
          cssDegree,
          votePrice,
          votedDetails,
          disableVoteButton,
          submit: () => {
            // console.log('votebutton',selectedOption)
            if(selectedTimeFrame == 4 && userInfo?.goldenVote==0){
              showToast("You are not eligible to perform Golden Vote!", ToastType.ERROR);            
              setHeaderCpmStart(false)
              setOneVoteOnly(false)
              setSelectedOption(3)
            }
            else if (
              selectedTimeFrame !== undefined &&
              selectedOption !== undefined
            ) {
              setClickEnable(true);
              setDisableVoteButton((prev) => !prev);
              // SubmitVote()
              setTimeout(() => {
                throttled_vote();
                setDisableVoteButton((prev) => !prev);
              }, 700);
            } else {
            }
          },
          option1: {
            buttonText: ["vote", "BULL"],
            image: <Bull />,
            alt: "bull",
            ...coin,
          },
          option2: {
            buttonText: ["vote", "BEAR"],
            image: <Bear />,
            alt: "bear",
            ...coin,
          },
          texts: {
            // yourVote: translate("BULL or BEAR, what’s your VOTE? Vote wisely!"),
            // yourVote: translate("Place your vote"),
            yourVote: translate("Vote for your winner").toUpperCase(),
            // selectTimeFrame: translate("Select voting time frame"),
            selectTimeFrame: translate(
              "Select a time frame for your vote"
            ).toUpperCase(),
            tooltip: translate(tooltipText),
          },
          setpopUpOpen,
          vote: propVote,
        }}
      >
        <VS>
          <div
            style={{
              position: "absolute",
              top: "40%",
              left: window.screen.width < 979 ? "4%" : "-10%",
              fontSize: window.screen.width < 979 ? "" : "20px",
            }}
          >
            {" "}
            VS
          </div>
        </VS>
      </VoteForm>
    </Container>
  );
};

export default CoinsForm;
