import { Buttons } from 'Components/Atoms/Button/Button'
import React, { useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import CountUp from 'react-countup'
import RewardSpin from 'Components/Profile/RewardSpinWheel/RewardSpin'
import styled from 'styled-components'
import AppContext from 'Contexts/AppContext'
import { VoteContext, VoteDispatchContext } from 'Contexts/VoteProvider'
import { CompletedVotesDispatchContext } from 'Contexts/CompletedVotesProvider'
import UserContext from 'Contexts/User'
import useSound from 'use-sound'
// @ts-ignore
import claimSound from '../../assets/sounds/claimReward.m4a';
import FreeRewardSpin from './FreeRewardSpin'
import MegaRewardSpin from './MegaRewardSpin'
const Popuphead = styled.p`
  font-size: 25px;
  font-weight: 600;
  background: linear-gradient(180deg, #fff8a6 29.44%, #ffc00b 73.33%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  // text-shadow: 0px 1px 3px #FFCB35;
  font-family: Poppins;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 2px;
  text-transform: uppercase;
`;


interface ExtraFreeSpinProps {
  type?:any,
  GoldenVote?:any,
  setLessTimeVoteDetails: React.Dispatch<React.SetStateAction<any>>,
  setOpenPopup: React.Dispatch<React.SetStateAction<any>>;
}
  const ExtraSpin : React.FC<ExtraFreeSpinProps> = ({type, GoldenVote,setLessTimeVoteDetails,setOpenPopup})=> {
  const [showGetVTE, setShowGetVTE] = React.useState(false);
    const [showCoinMove, setShowCoinMove] = React.useState(true);
    const [afterLoad, setafterLoad ] = React.useState<any>(false);
    const {setAnimateBox,setAfterVotePopup, } = useContext(AppContext);
    const { headerCpmStart,setHeaderCpmStart, } = useContext(AppContext);    
    const voteDetails = useContext(VoteContext);
    const setVoteDetails = useContext(VoteDispatchContext);
    const setCompletedVotes = useContext(CompletedVotesDispatchContext);
    const { userInfo } = useContext(UserContext);
    const [claimRewardSoundplay, { pause: claimRewardSoundpause }] = useSound(claimSound);

    const [showButtonMove, setShowButtonMove] = useState<boolean>(true);
    const [FreeSpinModule, setFreeSpinModule] = useState<boolean>(false);
    const [fresVoteValue, setFresVoteValue] = useState<number>(0);
    // const handleClose = () => setFreeSpinModule(false);
  const handleShow = () => setFreeSpinModule(true); 
  
  console.log(GoldenVote,"voteDetailsvoteDetails11221122")
  const removeVote = () => {
    let temp = {};
    setVoteDetails((prev) => {
      // console.log(Object.keys(prev?.activeVotes),"Object.keys(prev?.activeVotes)")
      Object.keys(prev?.activeVotes).map((key: string) => {
        console.log(key,"Object.keys(prev?.activeVotes)")
        if (/* prev?.activeVotes[key].expiration > new Date().getTime() &&  */GoldenVote?.voteId !== voteDetails?.activeVotes[key].voteId) {
          temp = { ...temp, [`${voteDetails?.activeVotes[key].coin}_${voteDetails?.activeVotes[key]?.timeframe?.seconds}`]: voteDetails?.activeVotes[key] }
        }
      });
      console.log(GoldenVote, prev?.activeVotes.voteId,"temptemptemp")
      return {
        ...prev,
        lessTimeVote: undefined,
        activeVotes: temp,
        openResultModal: false,        
      };
    });
    if (Object.keys(temp)?.length <= 0 && (Number(userInfo?.voteValue) + Number(userInfo?.rewardStatistics?.extraVote)) <= 0) {
      setAfterVotePopup(true);
    }
    setCompletedVotes(prev => prev.filter(value => value.voteId != GoldenVote.voteId));
    setLessTimeVoteDetails(undefined);      
  }

  const handleClose = () => {
    removeVote();    
    setHeaderCpmStart(true);            
    setAnimateBox(true);
    setOpenPopup("none")
    setTimeout(() => {
      setAnimateBox(false);        
  }, 10000 )
  };

  useEffect(() =>{
    handleShow()
  },[type])


  return (
    <div>
          {/* reward modal 3 */}
          <Modal
            show={
                FreeSpinModule
            } onHide={handleClose}
            backdrop="static"            
            contentClassName={"modulebackground ForBigDiv"}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            style={{ backgroundColor: "rgba(0,0,0,0.9)", zIndex: "2200" }}
            animation={false}
          >
            <div className='d-flex justify-content-center GoldenVoteText'>
            <p>Golden Vote Reward</p>
          </div>
            <Modal.Body className="d-flex  flex-column  justify-content-between align-items-center"
              style={{
                width: `${window.screen.width > 767 ? "500px" : "100%"}`,
                height: "400px"
              }}
            >
              {!showButtonMove ?<>              
              <Popuphead>Congrats!</Popuphead>
              {/* @ts-ignore */}
              <div className=''>
                  <p style={{ fontSize: "24px", color: "white", fontWeight: "600" }}>You've won {
                  <CountUp
                    end = {fresVoteValue}
                    duration = { 2.75}
                  />
                  } votes</p>
              </div>              
              {/* <Modal.Footer> */}
              <div className="d-flex justify-content-center ">
                  <Buttons.Primary className="mx-2"                                        
                    onClick={() => {
                      handleClose()
                    }}>COLLECT YOUR VOTES</Buttons.Primary>
              </div>
              </> :
                <div className="w-100 d-flex justify-content-center align-items-center m-auto"                
                >               
                  {type=="MEGASPIN"?
                  <MegaRewardSpin
                    setFresVoteValue={setFresVoteValue}
                    setShowButtonMove={setShowButtonMove}
                    rewardTimer={GoldenVote}
                    claimRewardSoundplay={claimRewardSoundplay}
                    />
                  :<FreeRewardSpin
                    setFresVoteValue={setFresVoteValue}
                    setShowButtonMove={setShowButtonMove}
                    rewardTimer={GoldenVote}
                    claimRewardSoundplay={claimRewardSoundplay}
                  />}
              </div>}
            </Modal.Body>            
          </Modal>
        </div>
  )
}

export default ExtraSpin
