import React, { useContext, useEffect, useReducer, useState } from 'react';
import './Voteimpactbar.css';
import bull from '../../../assets/svg/bull.svg';
import bear from '../../../assets/svg/bear.svg';
import { VoteResultProps } from 'common/models/Vote';
import { Coin } from 'common/models/Coin';
import { VoteEndCoinPriceContext } from 'Contexts/VoteEndCoinPrice';
import CoinsContext from 'Contexts/CoinsContext';
import { VoteDispatchContext } from 'Contexts/VoteProvider';
import { decimal } from "../../Profile/utils";
import BullBearButton from './BullBearButton';



const Voteimpactbar = ({    
    pairCoinResult,
    vote,
    coins,
    symbol1,
    symbol2
}: {    
    pairCoinResult?: any,
    vote: VoteResultProps;
    coins: { [symbol: string]: Coin };
    symbol1: string;
    symbol2: string;
}) => {
    const totalBoxes = 10;    
    const voteEndCoinPrice = useContext(VoteEndCoinPriceContext);
    const [persentValue, setPersentValue] = useReducer((state: number, action: number) => {
        if (action > 100) {
            return 100;
        }
        return action < 0 ? 1 : ((typeof action == 'number' && !isNaN(action)) ? action : state);
    }, 50)
    const { allCoinsSetting, allPairsSetting } = useContext(CoinsContext)    
    const [priceRange, setPriceRange] = useState(1);
    const [pairPriceRange, setPairPriceRange] = useState(1);
    const [pairPriceRange2, setPairPriceRange2] = useState(1);
    const [priceRange2, setPriceRange2] = useState(1);
    const [widthValue, setWidthValue] = useState(0);
    const [widthValue2, setWidthValue2] = useState(0);
    const [borderColorLeft, setBorderColorLeft] = useState(["#2c2796","#433ba9","#433ba9","#6458c5","#6f60ce","#7e6dda","#7e6dda","#7e6dda","#7e6dda","#7e6dda","#7e6dda"]);    
    const [borderColorRight, setBorderColorRight] = useState(["#7e6dda", "#7e6dda", "#7e6dda", "#7e6dda", "#7e6dda", "#7e6dda","#6f60ce", "#6458c5", "#433ba9", "#433ba9", "#2c2796"]);    
    const setVoteDetails = useContext(VoteDispatchContext);
    useEffect(() => {       
        
        setVoteDetails((prev) => {
            let voteImpact: number = (persentValue < 40 ? 0 :
                (persentValue >= 40 && persentValue <= 60 ? 2 : 1)
            )
            return {
                ...prev, voteImpact: {
                    timeFrame: prev?.voteImpact?.timeFrame,
                    impact: voteImpact
                }
            }
        });         
    }, [persentValue]);
    
    useEffect(() => {
        getBorderColor()
    }, [JSON.stringify(voteEndCoinPrice[`${vote?.coin}_${vote?.timeframe?.seconds}`])/* coins[symbol1]?.price, coins[symbol2]?.price, vote?.valueVotingTime, coins[symbol1]?.randomDecimal, coins[symbol1]?.randomDecimal */])

    useEffect(() => {        
        if (symbol1 && !symbol2) {            
            setPriceRange(allCoinsSetting?.find((item: any) => item?.symbol == symbol1)?.voteBarRange[`${vote?.timeframe?.index}`])
        }
        if (symbol1 && symbol2) {
            setPairPriceRange(allPairsSetting?.find((item: any) => item?.symbol1 == symbol1)?.[`${symbol1}VoteBarRange`][`${vote?.timeframe?.index}`])            
            setPairPriceRange2(allPairsSetting?.find((item: any) => item?.symbol2 == symbol2)?.[`${symbol2}VoteBarRange`][`${vote?.timeframe?.index}`])            
        }
    }, [symbol1, allCoinsSetting, vote?.voteTime])
    

    const getBorderColor = () => {
        if (vote?.expiration < new Date().getTime()) {
            return;
        }

        //  difference  = (1.325789324095734).tofixed(1)        
        // setrandomdecimal = difference + 0.Math.floor(Math.random() * 9)
        // console.log(getPersentValue,"valueWithoutDecimal")
        // 2354435 * 1 = 2354435
        // setrandomdecimal remove decmail like 1.2 for 12  * dbcoinvoterangvalue if difference < 0 opact coin voteran other wish voted coin voterang
        if (symbol2 !== undefined) {
            console.log(pairCoinResult.difference, "pairCoinResult")
            const getDifference = Number(pairCoinResult.difference).toFixed(1);
            const addRandomDecimal = parseFloat(`${getDifference}${Math.floor(Math.random() * 9)}`)
            const valueWithoutDecimal = parseFloat(addRandomDecimal.toString().replace('.', ''));
            const getPersentValue = valueWithoutDecimal * (Number(getDifference) < 0 ? vote.direction == 0 ? pairPriceRange2 :
                pairPriceRange : vote.direction == 1 ? pairPriceRange2 : pairPriceRange);                                        
            if (pairCoinResult.difference == 0) {
                setPersentValue(50)
            }
            if (Number(getPersentValue) < 0) {
                setPersentValue(Math.floor(Math.random() * 49) + 1)
                //  add minimum 0 and maximum 100 for any value 
            }
            if (Number(getPersentValue) > 0) {
                setPersentValue(Math.floor(Math.random() * 99) + 51)
                //  add minimum 0 and maximum 100 for any value 
            }            

        } else if (symbol2 == undefined && !Array.isArray(vote?.valueVotingTime)) {
            // range bar for single coin
            if (!vote?.valueVotingTime) {
                setPersentValue(50)
                return false
            }

            let tempNewValue = (parseFloat(voteEndCoinPrice?.[`${vote?.coin}_${vote?.timeframe?.seconds}`]?.coin1 || '0') * decimal[symbol1].multiply) * (symbol1 == "BTC" || symbol1 == "ETH" ? 1 : 10);
            let tempOldValue = (vote?.valueVotingTime * decimal[symbol1].multiply) * (symbol1 == "BTC" || symbol1 == "ETH"  ? 1 :10) ;
            if (tempNewValue === tempOldValue) {
                setPersentValue(50);
            } else if (vote?.direction == 0) {
                const temp = (tempNewValue - tempOldValue) * priceRange;
                console.log(temp, "temp-====1")
                setPersentValue(50 + temp);
            }
            else {
                const temp = (tempOldValue - tempNewValue) * priceRange;
                console.log(temp, "temp-====2")
                setPersentValue(50 + temp);
            }
        }
    };

       
    
    useEffect(() => {                
        GetWidthValue()
    }, [
        pairCoinResult,
        vote,
        coins,
        symbol1,
        symbol2
    ]);
    console.log(symbol1,
        symbol2, "symbol1symbol2")
    
    console.log(vote.direction, "vote.direction")
    
    const GetWidthValue = () => {
        // check for mid first
        if (persentValue > 39 && persentValue < 61) {
            setWidthValue(vote.direction == 0 ? Math.floor(Math.random() * (13 - 10 + 1)) + 10 :0)
            setWidthValue2(vote.direction == 1 ? Math.floor(Math.random() * (13 - 10 + 1)) + 10 : 0)
            return
        }
        // check for bull direction
        if (vote.direction == 0) {
            if (persentValue > 60) {
                setWidthValue(persentValue)
                setWidthValue2(0)
            }
            else if (persentValue < 40) {
                setWidthValue(0)
                setWidthValue2(100 - persentValue)
            }
            return
        }
        // check for bear direction
        if (vote.direction == 1) {
            if (persentValue > 60) {
                setWidthValue(0)
                setWidthValue2(persentValue)
            }
            else if (persentValue < 40) {
                setWidthValue(100 - persentValue)
                setWidthValue2(0)
            }
            return
        }


    }

    return (
        <div className="containerValue">
            {/* <div className='Fullbarimpact'>                
                <div className="boxes"
                    style={{                        
                        left:"10px",
                        borderRadius:"10px 0px 0px 10px",
                    }}
                >                    
                    <div className='backGroundBox'
                         
                        style={{                            
                            background: `${vote.direction == 0 ? "linear-gradient(273deg, rgba(171,148,255,1) 0%, rgba(79,69,179,1) 70%, rgba(9,9,121,1) 100%)" :"linear-gradient(90deg, rgba(171, 148, 255, 1) 0%, rgba(79, 69, 179, 1) 70%, rgba(9, 9, 121, 1) 100%)"}`,                             
                        }}    
                    >                        
                    {Array.from({ length: totalBoxes }).map((_, index) => {                        
                        return (
                            <div key={index} className="boxchild"
                                style={{
                                    marginRight: `${index === totalBoxes - 1 ? "6px":"0px"}`, 
                                    borderRadius: `${index === 0 ? "10px 0px 0px 10px" : "50% 0% 0% 50%"}`,                                    
                                    borderRight:"none",
                                    borderTop:"none",
                                    borderBottom:"none",                                    
                                }}
                            ></div>
                        );
                    })}
                        <div className='whiteBoxvote'
                            style={{
                                
                                width: `${100 - widthValue}%`,                                
                                transition: "width 0.8s ease-in-out",
                            }}
                        >
                            <div className='shap-box'>                            
                            <div className='state-div'>

                            </div>
                            <div className='round-div'
                            style={{
                                width: `${100-widthValue < 95? "5px":"0px"}`,
                                backgroundColor:`${vote.direction ==0 ?borderColorRight[Math.round((100-widthValue2)/10)]:borderColorLeft[Math.round((100-widthValue2)/10)]}`,
                            }}
                            >

                            </div>
                            </div>
                        </div>
                </div>
                </div>
                
                <div className="centerBox">                   
                    <BullBearButton
                        pairCoinResult={pairCoinResult}
                    vote={vote}
                    coins={coins}
                    symbol1={symbol1}
                        symbol2={symbol2}
                    />
                </div>
                
                <div className="boxes"
                    style={{   
                        right: "10px",
                        borderRadius: `0px 10px 10px 0px`,
                    }}
                >
                    <div className='backGroundBox2'
                        style={{
                            background: `${vote.direction == 1 ? "linear-gradient(90deg, rgba(171, 148, 255, 1) 0%, rgba(79, 69, 179, 1) 70%, rgba(9, 9, 121, 1) 100%)" :"linear-gradient(273deg, rgba(171,148,255,1) 0%, rgba(79,69,179,1) 70%, rgba(9,9,121,1) 100%)"}`,
                        }}                            
                    >
                    {Array.from({ length: totalBoxes }).map((_, index) => {                        
                        return (
                            <div key={index} className="boxchild"
                                style={{                                    
                                    marginLeft: `${index == 0 ? "6px":"0px"}`, 
                                    borderRadius: `${index === totalBoxes - 1 ? "0px 10px 10px 0px" : "0% 50% 50% 0%"}`, 
                                    borderLeft:"none",
                                    borderTop:"none",
                                    borderBottom:"none",
                                }}
                            ></div>
                        );
                    })}
                        <div className='whiteBoxvote2'
                            style={{
                                width: `${100-widthValue2}%`,
                                transition:"width 0.8s ease-in-out",
                            }}
                        >
                            <div className='shap-box2'>
                            <div className='round-div2'
                            style={{
                                width: `${100-widthValue2 < 97? "5px":"0px"}`, 
                                backgroundColor:`${vote.direction ==1 ?borderColorRight[Math.round((100-widthValue2)/10)]:borderColorLeft[Math.round((100-widthValue2)/10)]}`,                                
                            }}
                            >

                            </div>
                            <div className='state-div2'>

                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className='Fullbarimpact'>
                {/* Left side (Negative side) */}
                <div className="boxes"
                    style={{
                        // padding: "10px 0px 10px 8px",
                        left:"10px",
                        borderRadius:"10px 0px 0px 10px",
                    }}
                >                    
                    <div className='backGroundBox'
                        // style={{
                        //     
                        // }}  
                        style={{                            
                            background: `${vote.direction == 0 ? "linear-gradient(273deg, rgba(171,148,255,1) 0%, rgba(79,69,179,1) 70%, rgba(9,9,121,1) 100%)" :"linear-gradient(90deg, rgba(171, 148, 255, 1) 0%, rgba(79, 69, 179, 1) 70%, rgba(9, 9, 121, 1) 100%)"}`,                             
                        }}    
                    >                        
                    {Array.from({ length: totalBoxes }).map((_, index) => {                        
                        return (
                            <div key={index} className="boxchild"
                                style={{
                                    // marginRight: `${index === totalBoxes - 1 ? "9px":"0px"}`,
                                    width: `${index === totalBoxes - 1 && `${window.screen.width > 767 ?"28%":"40%"}`}`,
                                    borderRadius: `${index === 0 ? "10px 0px 0px 10px" : "0px"}`,                                    
                                    // background: index < leftSideFilled ? gradientColor :
                                    //     isPartiallyFilled ? `linear-gradient(to right, ${gradientColor} ${leftSidePartial * 100}%, #fff ${leftSidePartial * 100}%)` : '#fff',
                                }}
                            ></div>
                        );
                    })}
                        <div className='whiteBox'
                            style={{
                                // width: `${persentValue}%`,
                                width: `${100 - widthValue}%`,
                                transition: "width 0.8s ease-in-out",
                            }}
                        >
                        </div>
                </div>
                </div>

                {/* Middle section with bull, vs, and bear */}
                <div className="centerBox">
                    
                    <BullBearButton
                        pairCoinResult={pairCoinResult}
                    vote={vote}
                    coins={coins}
                    symbol1={symbol1}
                        symbol2={symbol2}
                    />
                </div>

                {/* Right side (Positive side) */}
                <div className="boxes"
                    style={{   
                        right: "10px",
                        borderRadius: `0px 10px 10px 0px`,
                    }}
                >
                    <div className='backGroundBox2'
                        style={{
                            background: `${vote.direction == 1 ? "linear-gradient(90deg, rgba(171, 148, 255, 1) 0%, rgba(79, 69, 179, 1) 70%, rgba(9, 9, 121, 1) 100%)" :"linear-gradient(273deg, rgba(171,148,255,1) 0%, rgba(79,69,179,1) 70%, rgba(9,9,121,1) 100%)"}`,
                        }}                            
                    >
                    {Array.from({ length: totalBoxes }).map((_, index) => {                        
                        return (
                            <div key={index} className="boxchild"
                                style={{                                     
                                    // marginLeft: `${index == 0 ? "9px":"0px"}`,  
                                    width: `${index == 0 && `${window.screen.width > 767 ?"28%":"40%"}`}`,
                                    borderRadius: `${index === totalBoxes - 1 ? "0px 10px 10px 0px" : "0px"}`, 
                                }}
                            ></div>
                        );
                    })}
                        <div className='whiteBox2'
                            style={{
                                width: `${100-widthValue2}%`,
                                transition:"width 0.8s ease-in-out",
                            }}
                        >
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Voteimpactbar;



// import React, { useContext, useEffect, useReducer, useState } from 'react';
// import './Voteimpactbar.css';
// import bull from '../../../assets/svg/bull.svg';
// import bear from '../../../assets/svg/bear.svg';
// import { VoteResultProps } from 'common/models/Vote';
// import { Coin } from 'common/models/Coin';
// import { VoteEndCoinPriceContext } from 'Contexts/VoteEndCoinPrice';
// import CoinsContext from 'Contexts/CoinsContext';
// import { VoteDispatchContext } from 'Contexts/VoteProvider';
// import { decimal } from "../../Profile/utils";
// import BullBearButton from './BullBearButton';



// const Voteimpactbar = ({    
//     pairCoinResult,
//     vote,
//     coins,
//     symbol1,
//     symbol2
// }: {    
//     pairCoinResult?: any,
//     vote: VoteResultProps;
//     coins: { [symbol: string]: Coin };
//     symbol1: string;
//     symbol2: string;
// }) => {
//     const totalBoxes = 10;    
//     const voteEndCoinPrice = useContext(VoteEndCoinPriceContext);
//     const [persentValue, setPersentValue] = useReducer((state: number, action: number) => {
//         if (action > 100) {
//             return 100;
//         }
//         return action < 0 ? 1 : ((typeof action == 'number' && !isNaN(action)) ? action : state);
//     }, 50)
//     const { allCoinsSetting, allPairsSetting } = useContext(CoinsContext)    
//     const [priceRange, setPriceRange] = useState(1);
//     const [pairPriceRange, setPairPriceRange] = useState(1);
//     const [pairPriceRange2, setPairPriceRange2] = useState(1);
//     const [priceRange2, setPriceRange2] = useState(1);
//     const [widthValue, setWidthValue] = useState(0);
//     const [widthValue2, setWidthValue2] = useState(0);
//     const [borderColorLeft, setBorderColorLeft] = useState(["#7e6dda", "#7e6dda", "#7e6dda", "#7e6dda", "#7e6dda", "#7e6dda","#6f60ce", "#6458c5", "#9d87f3", "#9d87f3", "#2c2796"]);    
//     const [borderColorRight, setBorderColorRight] = useState(["#2c2796","#2c2796","#433ba9","#6458c5","#6f60ce","#7e6dda","#7e6dda","#7e6dda","#7e6dda","#7e6dda","#7e6dda"]);    
//     const [borderColorRight2, setBorderColorRight2] = useState(["#2c2796","#2c2796","#433ba9","#6458c5","#6f60ce","#7e6dda","#7e6dda","#7e6dda","#7e6dda","#7e6dda","#7e6dda"]);    
//     const [borderColorLeft2, setBorderColorLeft2] = useState(["#7e6dda", "#7e6dda", "#7e6dda", "#7e6dda", "#7e6dda", "#7e6dda","#6f60ce", "#6458c5", "#9d87f3", "#9d87f3", "#2c2796"]);    
//     const setVoteDetails = useContext(VoteDispatchContext);
//     useEffect(() => {       
        
//         setVoteDetails((prev) => {
//             let voteImpact: number = (persentValue < 40 ? 0 :
//                 (persentValue >= 40 && persentValue <= 60 ? 2 : 1)
//             )
//             return {
//                 ...prev, voteImpact: {
//                     timeFrame: prev?.voteImpact?.timeFrame,
//                     impact: voteImpact
//                 }
//             }
//         });         
//     }, [persentValue]);
    
//     useEffect(() => {
//         getBorderColor()
//     }, [JSON.stringify(voteEndCoinPrice[`${vote?.coin}_${vote?.timeframe?.seconds}`])/* coins[symbol1]?.price, coins[symbol2]?.price, vote?.valueVotingTime, coins[symbol1]?.randomDecimal, coins[symbol1]?.randomDecimal */])

//     useEffect(() => {        
//         if (symbol1 && !symbol2) {            
//             setPriceRange(allCoinsSetting?.find((item: any) => item?.symbol == symbol1)?.voteBarRange[`${vote?.timeframe?.index}`])
//         }
//         if (symbol1 && symbol2) {
//             setPairPriceRange(allPairsSetting?.find((item: any) => item?.symbol1 == symbol1)?.[`${symbol1}VoteBarRange`][`${vote?.timeframe?.index}`])            
//             setPairPriceRange2(allPairsSetting?.find((item: any) => item?.symbol2 == symbol2)?.[`${symbol2}VoteBarRange`][`${vote?.timeframe?.index}`])            
//         }
//     }, [symbol1, allCoinsSetting, vote?.voteTime])
    

//     const getBorderColor = () => {
//         if (vote?.expiration < new Date().getTime()) {
//             return;
//         }

//         //  difference  = (1.325789324095734).tofixed(1)        
//         // setrandomdecimal = difference + 0.Math.floor(Math.random() * 9)
//         // console.log(getPersentValue,"valueWithoutDecimal")
//         // 2354435 * 1 = 2354435
//         // setrandomdecimal remove decmail like 1.2 for 12  * dbcoinvoterangvalue if difference < 0 opact coin voteran other wish voted coin voterang
//         if (symbol2 !== undefined) {
//             console.log(pairCoinResult.difference, "pairCoinResult")
//             const getDifference = Number(pairCoinResult.difference).toFixed(1);
//             const addRandomDecimal = parseFloat(`${getDifference}${Math.floor(Math.random() * 9)}`)
//             const valueWithoutDecimal = parseFloat(addRandomDecimal.toString().replace('.', ''));
//             const getPersentValue = valueWithoutDecimal * (Number(getDifference) < 0 ? vote.direction == 0 ? pairPriceRange2 :
//                 pairPriceRange : vote.direction == 1 ? pairPriceRange2 : pairPriceRange);                                        
//             if (pairCoinResult.difference == 0) {
//                 setPersentValue(50)
//             }
//             if (Number(getPersentValue) < 0) {
//                 setPersentValue(Math.floor(Math.random() * 49) + 1)
//                 //  add minimum 0 and maximum 100 for any value 
//             }
//             if (Number(getPersentValue) > 0) {
//                 setPersentValue(Math.floor(Math.random() * 99) + 51)
//                 //  add minimum 0 and maximum 100 for any value 
//             }            

//         } else if (symbol2 == undefined && !Array.isArray(vote?.valueVotingTime)) {
//             // range bar for single coin
//             if (!vote?.valueVotingTime) {
//                 setPersentValue(50)
//                 return false
//             }

//             let tempNewValue = (parseFloat(voteEndCoinPrice?.[`${vote?.coin}_${vote?.timeframe?.seconds}`]?.coin1 || '0') * decimal[symbol1].multiply) * (symbol1 == "BTC" || symbol1 == "ETH" ? 1 : 10);
//             let tempOldValue = (vote?.valueVotingTime * decimal[symbol1].multiply) * (symbol1 == "BTC" || symbol1 == "ETH"  ? 1 :10) ;
//             if (tempNewValue === tempOldValue) {
//                 setPersentValue(50);
//             } else if (vote?.direction == 0) {
//                 const temp = (tempNewValue - tempOldValue) * priceRange;
//                 console.log(temp, "temp-====1")
//                 setPersentValue(50 + temp);
//             }
//             else {
//                 const temp = (tempOldValue - tempNewValue) * priceRange;
//                 console.log(temp, "temp-====2")
//                 setPersentValue(50 + temp);
//             }
//         }
//     };

       
    
//     useEffect(() => {                
//         GetWidthValue()
//     }, [
//         pairCoinResult,
//         vote,
//         coins,
//         symbol1,
//         symbol2
//     ]);
//     console.log(symbol1,
//         symbol2, "symbol1symbol2")
    
//     console.log(vote.direction, "vote.direction")
    
//     const GetWidthValue = () => {
//         // // check for mid first        
//         if (persentValue==50) {         
//             setWidthValue(10)
//             setWidthValue2(10)            
//             return
//         }
//         if (persentValue > 39 && persentValue < 61) {            
//             setWidthValue(vote.direction == 0 ? 12:0)
//             setWidthValue2(vote.direction == 1 ? 16: 0)
//             return
//         }
//         // check for bull direction
//         if (vote.direction == 0) {
//             if (persentValue > 60) {
//                 setWidthValue(persentValue)
//                 setWidthValue2(0)
//             }
//             else if (persentValue < 40) {
//                 setWidthValue(0)
//                 setWidthValue2(100 - persentValue)
//             }
//             return
//         }
//         // check for bear direction
//         if (vote.direction == 1) {
//             if (persentValue > 60) {
//                 setWidthValue(0)
//                 setWidthValue2(persentValue)
//             }
//             else if (persentValue < 40) {
//                 setWidthValue(100 - persentValue)
//                 setWidthValue2(0)
//             }
//             return
//         }


//     }

//     return (
//         <div className="containerValue">
//             <div className='Fullbarimpact'>
//                 {/* Left side (Negative side) */}
//                 <div className="boxes"
//                     style={{
//                         // padding: "10px 0px 10px 8px",
//                         left:"10px",
//                         borderRadius:"10px 0px 0px 10px",
//                     }}
//                 >                    
//                     <div className='backGroundBox'
//                         // style={{
//                         //     
//                         // }}  
//                         style={{                            
//                             background: `${vote.direction == 0 ? "linear-gradient(273deg, rgba(171,148,255,1) 0%, rgba(79,69,179,1) 70%, rgba(9,9,121,1) 100%)" :"linear-gradient(90deg, rgba(171, 148, 255, 1) 0%, rgba(79, 69, 179, 1) 70%, rgba(9, 9, 121, 1) 100%)"}`,                             
//                         }}    
//                     >                        
//                     {Array.from({ length: totalBoxes }).map((_, index) => {                        
//                         return (
//                             <div key={index} className="boxchild"
//                                 style={{
//                                     borderRadius: `${index === 0 ? "10px 0px 0px 10px" : "50% 0% 0% 50%"}`,                                    
//                                     borderRight:"none",
//                                     borderTop:"none",
//                                     borderBottom:"none",                                           
//                                     width:`${index === totalBoxes - 1 ? "25%" : "19%" }`,                         
//                                 }}
//                             ></div>
//                         );
//                     })}
//                         <div className='whiteBoxvote'
//                             style={{
//                                 // width: `${persentValue}%`,
//                                 width: `${100 - widthValue > 95 ? 105:100-widthValue}%`,
//                                 transition: "width 0.8s ease-in-out",
//                             }}
//                         >
//                             <div className='shap-box'>                            
//                             <div className='state-div'>

//                             </div>
//                             <div className='round-div'
//                             style={{
//                                 backgroundColor:`${vote.direction ==0 ?borderColorRight[Math.round((100-widthValue2)/10)]:borderColorLeft[Math.round((100-widthValue2)/10)]}`,
//                             }}
//                             >

//                             </div>
//                             </div>
//                         </div>
//                 </div>
//                 </div>

//                 {/* Middle section with bull, vs, and bear */}
//                 <div className="centerBox">                   
//                     <BullBearButton
//                         pairCoinResult={pairCoinResult}
//                     vote={vote}
//                     coins={coins}
//                     symbol1={symbol1}
//                         symbol2={symbol2}
//                     />
//                 </div>

//                 {/* Right side (Positive side) */}
//                 <div className="boxes"
//                     style={{   
//                         right: "10px",
//                         borderRadius: `0px 10px 10px 0px`,
//                     }}
//                 >
//                     <div className='backGroundBox2'
//                         style={{
//                             background: `${vote.direction == 1 ? "linear-gradient(90deg, rgba(171, 148, 255, 1) 0%, rgba(79, 69, 179, 1) 70%, rgba(9, 9, 121, 1) 100%)" :"linear-gradient(273deg, rgba(171,148,255,1) 0%, rgba(79,69,179,1) 70%, rgba(9,9,121,1) 100%)"}`,
//                         }}                            
//                     >
//                     {Array.from({ length: totalBoxes }).map((_, index) => {                        
//                         return (
//                             <div key={index} className="boxchild"
//                                 style={{                                    
//                                     borderRadius: `${index === totalBoxes - 1 ? "0px 10px 10px 0px" : "0% 50% 50% 0%"}`, 
//                                     borderLeft:"none",
//                                     borderTop:"none",
//                                     borderBottom:"none",
//                                     width:`${index === 0 ? "26%" : "19%" }`,    
//                                 }}
//                             ></div>
//                         );
//                     })}
//                         <div className='whiteBoxvote2'
//                             style={{
//                                 width: `${100-widthValue2 > 95 ? 105 :100-widthValue2}%`,
//                                 transition:"width 0.8s ease-in-out",
//                             }}
//                         >
//                             <div className='shap-box2'>
//                             <div className='round-div2'
//                             style={{
//                                 backgroundColor:`${vote.direction ==1 ?borderColorRight2[Math.round((100-widthValue2)/10)]:borderColorLeft2[Math.round((100-widthValue2)/10)]}`,
//                             }}
//                             >

//                             </div>
//                             <div className='state-div2'>

//                             </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default Voteimpactbar;