/** @format */

import React, { useContext, useEffect, useState } from "react";
import { Badge, Col, Container, Row } from "react-bootstrap";
import UserContext from "../Contexts/User";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import UserCard from "../Components/Profile/UserCard";
import styled from "styled-components";
import { Gradient2 } from "../styledMixins";
import { isV1, PageContainer } from "../Components/App/App";
import ImageTabs from "../Components/Profile/ImageTabs";
import Votes from "../Components/icons/votes2";
// import Mine from "../Components/icons/mine";
import Minenew from "../Components/icons/minenew";
import Share from "../Components/icons/share";
import ProfileFollowing from "../Components/icons/ProfileFollowing";
import Gallery from "../Components/icons/Gallery";
import Notifications from "../Components/icons/notifications";
import NotificationContext, { ToastType } from "../Contexts/Notification";
import UpdateAvatars from "../Components/Profile/UpdateAvatars";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../firebase";
import { AvatarType } from "../assets/avatars/Avatars";
import { toast } from "react-toastify";
import { useTranslation } from "../common/models/Dictionary";
import Spinner from "../Components/Spinner";
import UserIcon from "../Components/icons/userIcon";
import SecurityIcon from "../Components/icons/securityIcon";
// import ProfileNftGallery from "./ProfileNftGallery";
import Wallet from "../Components/icons/Wallet";
import { texts } from "../Components/LoginComponent/texts";
// import { handleSoundClick } from "../common/utils/SoundClick";
import Sharenew from "Components/icons/sharenew";
import Votesnew from "Components/icons/votesnew";
import Gallerynew from "Components/icons/Gallerynew";
import ProfileFollowingnew from "Components/icons/ProfileFollowingnew";
import Notificationsnew from "Components/icons/notificationsnew";

import PaymentHitory from "Components/icons/PaymentHitory";
import FoundationIcon from "Components/icons/FoundationIcon";
import AppContext from "Contexts/AppContext";
import useSound from 'use-sound';
// @ts-ignore
import buttonClick from '../assets/sounds/buttonClick.mp3';
import { getUsers } from "Components/Profile/Follow";
import Trade from "Components/icons/Trade";
import moment from "moment";

export enum ProfileTabs {
  profile = "profile",
  password = "password",
  followers = "followers",
  history = "history",
  trade = "trade",
  // foundationshow = "foundationshow",
  mine = "mine",
  edit = "info",
  wallet = "wallet",
  votes = "votes",
  notifications = "notifications",
  ProfileNftGallery = "CollectiblesAlbum",
  ProfileNftGalleryType = "CollectiblesAlbum/:type",
  share = "share",
}

export const CardContainer = styled.div`
overflow:hidden;
&.BigScreen{
background:#d4d0f3;
  height: 100px;
  padding: 0;
  
  border-radius: 0 0 0 0;
}
   &.expanded {
    @media (min-width: 768px) {
      height: 140px;
    }
  }
`;

export const OuterContainer = styled.div`
  background: ${window.screen.width < 979 ? "var(--color-d4d0f3)" : ""};
  position: relative;
  // border:3px solid red; 
  z-index: 0;

`;
const MyBadge = styled(Badge)`
  background-color: var(--color-6352e8);
  box-shadow: 0 3px 6px #00000029;
  // border-radius: 0 0 6px 6px;
  font-size: 14px;
  opacity: 1;
  width: auto;
  color: var(--white);
  padding:13px;
  margin-top:10px;
`;

const MyBadge1 = styled(Badge)`
padding: 6px;
width: auto;
background: rgb(212, 208, 243);
color: rgb(99, 82, 232);
border-radius: 30px;
font-size: 10px;
margin-top: 10px;
display:flex;
justify-content: center;
alignItems: center;
`;

const Profile = () => {
  const [handleSoundClick] = useSound(buttonClick);
  const { userInfo, user } = useContext(UserContext);
  const { avatarImage, setAvatarImage, userChildern,
    setUserChildern,
    userLeadersData,
    setUserLeadersData,
    userSubscribersData,
    setUserSubscribersData,
  } = useContext(AppContext);
  const { showToast } = useContext(NotificationContext);
  const [avatarMode, setAvatarMode] = useState(false);
  const location = useLocation();
  const pathname = location.pathname.replace("/profile/", "");
  const [chosenByDefault, setChosenByDefault] = useState(pathname);
  let navigate = useNavigate();
  const translate = useTranslation();

  useEffect(() => {
    setChosenByDefault(pathname);

    return () => setAvatarMode(false);
  }, [pathname]);


  useEffect(() => {
    if (userInfo?.children && (userInfo?.children.length != userChildern?.length)) {
      // userChildern, setUserChildern
      getUsers({ users: userInfo?.children, setUsers: setUserChildern });
    }
  }, [userInfo?.children]);


  useEffect(() => {
    console.log(userInfo?.leader && userInfo?.leader.length , userLeadersData?.length,"getbothlengt")
    if (userInfo?.leader && (userInfo?.leader.length > 0)) {
      console.log(userInfo?.leader," userInfo?.leader")
      getUsers({ users: userInfo?.leader, setUsers: setUserLeadersData })
    }
    // else {
    //   setUserLeadersData([]);
    // }
  }, [userInfo?.leader]);
  useEffect(() => {
    if (userInfo?.subscribers && (userInfo?.subscribers.length > 0)) {
      getUsers({ users: userInfo?.subscribers, setUsers: setUserSubscribersData });
    }
  }, [userInfo?.subscribers]);


  const onSubmitAvatar = async (type: AvatarType) => {
    if (user?.uid) {
      const userRef = doc(db, "users", user?.uid);
      try {
        await setDoc(userRef, { avatar: type }, { merge: true });
        setAvatarImage("")
        showToast(translate(texts.UserInfoUpdate));

        toast.dismiss();
      } catch (e) {
        showToast(translate(texts.UserFailUpdate), ToastType.ERROR);
      }
    }
  };




  return (
    <PageContainer fluid color='var(--pixie-powder)' radius={0} shadow='' className="">
      {avatarMode && (
        // <Container className="py-3" fluid>
        <UpdateAvatars
          {...{
            onSubmit: onSubmitAvatar,
            onClose: () => setAvatarMode(false),
          }}
        />
        // </Container>
      )}
      {!avatarMode && (
        <OuterContainer>

          <CardContainer className={`${window.screen.width > 979 ? "BigScreen " : ""} ${userInfo?.isUserUpgraded || (userInfo?.miningPass?.expiryDate.seconds > moment().unix()) ? 'expanded' : ''}`} style={{ backgroundColor: '' }}>
            <>
              {!userInfo?.paid && (
                <Row
                  className='d-flex justify-content-center'
                >
                  <>
                 {userInfo?.isUserUpgraded || (userInfo?.miningPass?.expiryDate.seconds > moment().unix()) ?  
                 <MyBadge1 bg='-' onClick={() => {
                    handleSoundClick()
                    navigate("/upgrade")
                  }} style={{
                    cursor: "pointer", boxShadow: `${userInfo?.isUserUpgraded || (userInfo?.miningPass?.expiryDate.seconds > moment().unix()) ? "#FFD700 1px 0px 5px 2px" : "none"}`,
                  }} >
                    {/* {translate("upgrade your account")} */}
                    {/* @ts-ignore */}
                        {userInfo?.isUserUpgraded || (userInfo?.miningPass?.expiryDate.seconds > moment().unix())? ("Verified Miner").toUpperCase() : ""}
                  </MyBadge1>
                :
                <MyBadge bg='-' onClick={() => {
                  handleSoundClick()
                  navigate("/upgrade")
                }} style={{ cursor: "pointer",height: "140px"
                }} >
                  {/* {translate("upgrade your account")} */}
                  {/* @ts-ignore */}
                  {userInfo?.isUserUpgraded || (userInfo?.miningPass?.expiryDate.seconds > moment().unix())?  ("You are a miner").toUpperCase() : ""}
                </MyBadge>
                }
                  </>
                </Row>
              )}
              <UserCard user={userInfo} onClick={() => setAvatarMode(true)}>
                {window.screen.width < 979 && (
                  <Container
                    fluid
                    style={{
                      // paddingTop: 60,
                      paddingLeft: "0px",
                      paddingRight: "0px",
                    }}
                  >
                    {![
                      ProfileTabs.edit as string,
                      ProfileTabs.password as string,
                      ProfileTabs.wallet as string,
                      ProfileTabs.history as string,
                      // ProfileTabs.foundationshow as string,                    
                    ].includes(pathname) && (
                        <ImageTabs
                          {...{
                            chosenByDefault,
                            handleSelect: (eventKey: string | null) => {
                              if (isV1() && eventKey === ProfileTabs.mine) {
                                showToast(
                                  translate(texts.FeatureAvailableSoon),
                                  ToastType.INFO
                                );
                                return;
                              }
                              navigate("./" + eventKey, { replace: true });
                            },
                            tabs: [
                              {
                                component: <></>,
                                label: "Mining",
                                icon: <Minenew />,
                                eventKey: ProfileTabs.mine,
                              },
                              {
                                component: <></>,
                                label: "Pool Mining",
                                icon: <Sharenew />,
                                eventKey: ProfileTabs.share,
                              },
                              {
                                component: <></>,
                                label: ProfileTabs.votes,
                                icon: <Votesnew />,
                                eventKey: ProfileTabs.votes,
                              },
                              {
                                component: <></>,
                                label: ProfileTabs.ProfileNftGallery,
                                icon: <Gallerynew />,
                                eventKey: ProfileTabs.ProfileNftGallery,
                              },


                              {
                                component: <></>,
                                label: ProfileTabs.followers,
                                icon: <ProfileFollowingnew />,
                                eventKey: ProfileTabs.followers,
                              },
                              {
                                component: <></>,
                                label: ProfileTabs.notifications,
                                icon: <Notificationsnew />,
                                eventKey: ProfileTabs.notifications,
                              },
                              {
                                component: <></>,
                                label: ProfileTabs.trade,
                                icon: <Trade />,
                                eventKey: ProfileTabs.trade,
                              },
                            ],
                          }}
                        />
                      )}
                    {[
                      ProfileTabs.edit as string,
                      ProfileTabs.password as string,
                      ProfileTabs.wallet as string,
                      ProfileTabs.history as string,
                      // ProfileTabs.foundationshow as string,
                    ].includes(pathname) &&
                      window.screen.width < 979 && (
                        <ImageTabs
                          {...{
                            chosenByDefault,
                            handleSelect: (eventKey: string | null) => {
                              if (isV1() && eventKey === ProfileTabs.mine) {
                                showToast(
                                  translate(texts.FeatureAvailableSoon),
                                  ToastType.INFO
                                );
                                return;
                              }
                              navigate("./" + eventKey, { replace: true });
                            },
                            tabs: [
                              {
                                component: <></>,
                                label: 'Info',
                                icon: <UserIcon />,
                                eventKey: ProfileTabs.edit,
                              },
                              {
                                component: <></>,
                                label: 'Security',
                                icon: <SecurityIcon />,
                                eventKey: ProfileTabs.password,
                              },
                              {
                                component: <></>,
                                label: ProfileTabs.wallet,
                                icon: <Wallet />,
                                eventKey: ProfileTabs.wallet,
                              },
                              {
                                component: <></>,
                                label: "Payment",
                                icon: <PaymentHitory />,
                                eventKey: ProfileTabs.history,
                              },
                              // {
                              //   component: <></>,
                              //   label: "Foundation",
                              //   icon: <FoundationIcon/>,
                              //   eventKey: ProfileTabs.foundationshow,
                              // },
                            ],
                          }}
                        />
                      )}
                  </Container>
                )}
              </UserCard>
            </>
          </CardContainer>
        </OuterContainer>
      )}
      <div
        className='p-0 gr_bg py-4'
        style={{ minHeight: window.screen.width < 979 ? "68vh" : "70vh" }}
      >
        <div className="w-100" style={{ color: "var(--black)" }}>
          <div className='p-0 col'>
            {/* <Col > */}
            <Outlet />
          </div>
        </div>
      </div>
    </PageContainer>
  )
  // : (
  //   <div
  //     className='d-flex justify-content-center align-items-center'
  //     style={{ height: "100vh", width: "100vw" }}
  //   >
  //     <Spinner />
  //   </div>
  // );
};

export default Profile;
