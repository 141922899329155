import AppContext from 'Contexts/AppContext';
import React, { useContext, useEffect, useState } from 'react'
import Wheel from './wheel/wheel';
import Streak from './streakBonus/streak';
import { texts } from 'Components/LoginComponent/texts';
import { Buttons } from "Components/Atoms/Button/Button";
import { CurrentCMPContext } from "Contexts/CurrentCMP";
import Swal from "sweetalert2";
import { Modal } from "react-bootstrap";
import { Navigate, useNavigate } from 'react-router-dom';
import UserContext from 'Contexts/User';

function SpinWheel() {
  const [modalShow, setModalShow] = React.useState(false);
  const [canNotSpin, setCanNotSpin] = React.useState(false);
  var urlName = window.location.pathname.split('/');
  const UrlCheck = urlName.includes("Wheel")
  let navigate = useNavigate();
  const { getSpinData, megaSpinData, freeSpinData,spinAccessLimit} = useContext(AppContext);
  const { userInfo } = useContext(UserContext);

  const currentCMP = useContext(CurrentCMPContext);
  const currentCMPDiff = Math.floor((userInfo?.voteStatistics?.score || 0) / 100);
  const prevCMPDiff = Math.floor(((userInfo?.voteStatistics?.score || 0) - currentCMP) / 100);
  const score = (userInfo?.voteStatistics?.score || 0) - ((userInfo?.rewardStatistics?.total || 0) * 100);

  const remainingCMP = ((currentCMP > 0 && currentCMPDiff > prevCMPDiff && (userInfo?.voteStatistics?.score || 0) > 0) ? 100 : score);
  const remainingReward = (userInfo?.rewardStatistics?.total || 0) - (userInfo?.rewardStatistics?.claimed || 0);

  // const goBack = () => {
  //   Navigate(-1);
  // }

  const handleClosePopUp = () => setModalShow(false);
  const handleShowPopUp = (free:number,mega:number) => {    
    if (free == 0 && mega == 0 && (100 - remainingCMP > 0)) {      
      setModalShow(true)
    }
  };
  const handleCanNotSpinClosePopUp = () => {
    goback();
    setCanNotSpin(false)
  };

  const goback = () => {
    navigate("/coins")
  }

  useEffect(() => {    
    if (freeSpinData?.spinnerEnabled == false && freeSpinData.totalSpinsUsed < 4) {
      setCanNotSpin(true)
    }    
  }, [freeSpinData?.spinnerEnabled]);

  console.log(freeSpinData,"freeSpinData?.freeSpinAvailable")
  return (
    <div>      
      {freeSpinData.hasOwnProperty('freeSpinAvailable') ? 
        <div>
          {userInfo?.voteValue > spinAccessLimit ?
          <>
            <Streak handleShowPopUp={handleShowPopUp} />
          </>:
          <>
          {Object.keys(freeSpinData).includes('spinnerEnabled') && Object.keys(freeSpinData).includes('votesReceivedFromFirst4Spins') && freeSpinData.totalSpinsUsed < 4 ? <Wheel handleShowPopUp={handleShowPopUp} />:
            freeSpinData?.freeSpinAvailable > 0 && megaSpinData?.megaSpinAvailable < 1 ? <Wheel handleShowPopUp={handleShowPopUp} /> : <Streak handleShowPopUp={handleShowPopUp} />
          }
          </>
          }
        </div>
        :
        <div
          style={{
            position: "fixed",
            height: "100%",
            // border: "2px solid red",`
            display: "flex",
            textAlign: "center",
            justifyContent: "center",
            // top: '0px',
            backgroundColor: "rgba(0,0,0,0.0)",
            right: "0px",
            bottom: "0px",
            zIndex: "9999",
            overflow: "hidden",
            width: "100%",
            alignItems: "center",
          }}
        >
          <span
            className="loading position-fixed"
            style={{
              color: "white",
              zIndex: "2220px",
              fontSize: "1.5em",
              top: `${window.scrollY + 300}px`,

              // marginTop: `${window.screen.width > 767 ? "50px" : "240px"}`
            }}
          >
            {texts?.waitForIt}
          </span>
        </div>
      }      
      {/* <Streak /> */}
      
      <div >
        <Modal
          show={
            modalShow
          } onHide={handleClosePopUp}
          // size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <div className="d-flex justify-content-end">
            <button type="button" className="btn-close " aria-label="Close" onClick={() => {
              handleClosePopUp()
            }}></button>
          </div>
          <Modal.Body className='WheelPopup'>
            {/* continue voting */}
            <div className='py-2  d-flex flex-column  justify-content-center'>
              <strong style={{ marginBottom:"25px", fontSize: "20px", display:"flex", justifyContent:"center" }}>Stay in the game</strong>              
              
              <p  className="text-center" style={{ fontSize: "20px" }}>Only {Number(100 - remainingCMP).toFixed(3)} CMP to reach your goal</p>
            </div>
          </Modal.Body >
          {/* <Modal.Footer> */}
          < div className="d-flex justify-content-center " >
            <Buttons.Primary className="mx-2" onClick={goback}>CONTINUE VOTING</Buttons.Primary>
          </div >
        </Modal>
      </div >

      <div >
        <Modal
          show={
            canNotSpin
          } onHide={handleCanNotSpinClosePopUp}
          // size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <div className="d-flex justify-content-end">
            {/* <button type="button" className="btn-close " aria-label="Close" onClick={() => {
              handleCanNotSpinClosePopUp()              
            }}></button> */}
          </div>
          <Modal.Body className='WheelPopup'>
            {/* continue voting */}
            <div className='py-2  d-flex flex-column  justify-content-center'>
              <strong style={{ marginBottom:"25px", fontSize: "20px", display:"flex", justifyContent:"center" }}>Finsh Your Votes</strong>                            
              <p style={{ fontSize: "20px" }}>Finish voting to unlock extra spins!</p>
            </div>
          </Modal.Body >
          {/* <Modal.Footer> */}
          < div className="d-flex justify-content-center " >
            <Buttons.Primary className="mx-2" onClick={goback}>CONTINUE VOTING</Buttons.Primary>
          </div >
        </Modal>
      </div >
    </div>
  )
}

export default SpinWheel