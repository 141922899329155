import { LoginProviders, providers } from "../../common/models/Login";
import LoginForm from "./LoginForm";
// import { Button, ListGroup } from "react-bootstrap";
import { texts } from "./texts";
import React, { FormEvent, useContext, useEffect, useState } from "react";
import { useTranslation } from "../../common/models/Dictionary";
import {
  AuthProvider,
  getAuth,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  signInWithCustomToken,
  User,
} from "firebase/auth";
import LoginWith from "./LoginWith";
import styled from "styled-components";
import {
  PoppinsBoldBlueViolet14px,
  PoppinsMediumBlack12px,
  PoppinsNormalBlueViolet12px,
} from "../../styledMixins";
import { User as AuthUser } from "@firebase/auth";
import { Callback } from "../../common/models/utils";
import NotificationContext, { ToastType } from "../../Contexts/Notification";
import { ToastContent, ToastOptions } from "react-toastify/dist/types";
import { capitalize } from "lodash";
import { FormControl, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import AppContext from "../../Contexts/AppContext";
import { Buttons } from "../Atoms/Button/Button";
import logo from "../../assets/svg/logoiconx2.png";
import v2elogo from "../../assets/svg/VTElogo.png";
import telegram from "../../assets/svg/telegram.png";
import copy from "copy-to-clipboard";
import googleLogo from "../../assets/svg/google_Logo.svg";
import { LoginButton } from '@telegram-auth/react';
import { httpsCallable } from "firebase/functions";
import { db, functions } from "../../firebase";
import { telegramLoginData } from "common/utils/helper";
import WaitForResponse from "Components/Profile/CardTrade/WaitForResponse";
import { v4 as uuidv4 } from 'uuid';
import { doc, setDoc } from "firebase/firestore";
const OR = styled.div`
  ${PoppinsMediumBlack12px};
  min-height: 19px;
  align-self: center;
  letter-spacing: 0;
  line-height: 11px;
  white-space: nowrap;
  text-transform: uppercase;
`;
const SignUp = styled.div`
  margin-left: 5px;
  margin-right: 7px;
  cursor: pointer;
  font-weight: 600 !important;
  // text-decoration:underline;
  ${PoppinsBoldBlueViolet14px};
`;
const ForgetPasswordText = styled.div`
  margin-left: 5px;
  margin-right: 7px;
  cursor: pointer;
  font-weight: 500 !important;
  // text-decoration:underline;
  ${PoppinsBoldBlueViolet14px};
`;
const DontHaveAccountText = styled.div`
  color: black;
`;
const H1 = styled.div`
margin-bottom:10px;
background: var(--color-160133) 0 0% no-repeat padding-box;
border: 2px solid var(--color-6352e8);
box-shadow: 0 3px 6px #00000029;
border-radius: 0 20px 20px 20px;
opacity: 1;
font: var(--font-style-normal) normal var(--font-weight-normal) var(--font-size-12)/var(--line-spacing-13) var(--font-family-poppins);
letter-spacing: var(--character-spacing-0);
color: var(--color-ffffff);
text-align: center;
padding:15px;
// font-size:13px;
}
`;
const Image = styled.img`
  // display: inline;
`;

const LoginButton2 = styled.div`
  border: none;
  border-radius: 5px;
  background: var(--white);
  color: var(--black);
  width: 100%;
  align-items: center;
  justify-content: start;
  cursor: pointer;
  &:hover {
    color: #fff;
    background-color: #d4d0f3;
    border-color: #d4d0f3;
  }
`;
const ContinueWith = styled.div`
  ${PoppinsNormalBlueViolet12px};
  min-height: 19px;
  // align-self: flex-end;
  margin-left: 32px;
  min-width: 131px;
  letter-spacing: 0;
  white-space: nowrap;
`;

export type LoginProps = {
  setForgetPassword: (s: boolean) => void;
  setUser: (user?: User | undefined) => void;
  setSignup: (s: boolean) => void;
  authProvider: (
    setUser: (user: AuthUser) => void,
    provider: AuthProvider,
    showToast: (
      content: ToastContent,
      type?: ToastType,
      options?: ToastOptions | undefined
    ) => void
  ) => Promise<void>;
  login: (
    e: FormEvent<HTMLFormElement>,
    callback: Callback<AuthUser>
  ) => Promise<void>;
};

const Login = ({
  setForgetPassword,
  setUser,
  setSignup,
  authProvider,
  login,
}: LoginProps) => {
  const translate = useTranslation();
  const { showToast } = useContext(NotificationContext);
  const {
    setLoginRedirectMessage,
    loginRedirectMessage,
    withLoginV2e,
    setWithLoginV2e,
    setLogin,
    setLoader,
  } = useContext(AppContext);
  const [smsVerification, setSmsVerification] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  console.log(smsVerification);
  useEffect(() => {
    return () => {
      setLoginRedirectMessage("");
    };
  }, []);
  const handleClose = () => {
    setSmsVerification("");
  };

  // var userAgent = navigator.userAgent.toLowerCase();

  function getInAppBrowser() {
    //@ts-ignore
    const ua = navigator.userAgent || navigator.vendor || window.opera;
    const getReferrer = document.referrer;
    if (
      /FBAN/.test(ua) ||
      /FBAV/.test(ua) ||
      /fb_iab/.test(ua) ||
      /FB_IAB/.test(ua) ||
      /facebook/.test(ua)
    ) {
      return true;
    } else if (/Instagram/.test(ua)) {
      return true;
    } else if (getReferrer == "https://t.co/") {
      return true;
    } else if (
      /TikTok/.test(ua) ||
      /musically/.test(ua) ||
      /ByteLocale/.test(ua) ||
      /ByteLo/.test(ua)
    ) {
      return true;
    } else {
      return false;
    }
  }

  const isInstagramAvailable = getInAppBrowser();
  const telegramLoginFuncation = httpsCallable(functions, "telegramLogin");
  const createUser = httpsCallable(functions, "createUser");
  const [isTelegram, setIsTelegram] = useState(false);  
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {        
    const isTelegramWebView = () => {
// @ts-ignore
      // const urlParams = new URLSearchParams(window.location.search);
      // const source = urlParams.get('source');
      // if (source === 'telegram') {
      //   setIsTelegram(true);
      //   localStorage.setItem('source', 'telegram');
      // } else {
      //   const storedSource = localStorage.getItem('source');
      //   if (storedSource === 'telegram') {
      //     setIsTelegram(true);
      //   }
      // }
      window?.Telegram?.WebApp?.ready();
      // @ts-ignore
    const initData = window?.Telegram?.WebApp?.initData;
      if (initData) {
        setIsTelegram(true)
      }
    };
    isTelegramWebView();
    // @ts-ignore
  }, [window?.Telegram?.WebApp?.initData]);
  const LoginWithTelegram = async () => {
    // @ts-ignore
    window.Telegram.WebApp.ready();
    // @ts-ignore
    const initData = window.Telegram.WebApp.initData;
    if (initData) {
      setIsLoading(true);
      const payloadData = await telegramLoginData(initData)                       
      const data = await telegramLoginFuncation(payloadData).then((result) => {        
        setIsLoading(false); 
        // @ts-ignore
        if(result?.data?.status == false){
          // @ts-ignore
          showToast(result?.data?.message, ToastType.ERROR)        
        }  
        return result
      }).catch((err) => {
        setIsLoading(false);
        showToast(err.message, ToastType.ERROR);             
      });;      
      // @ts-ignore                                       
      const auth = getAuth();                  
      // @ts-ignore                                       
      if(data?.data?.status){
        // @ts-ignore
        signInWithCustomToken(auth, data?.data?.result)
          .then((userCredential: any) => {            
            console.log(userCredential, "userCredential")
            // User is signed in                         
            const data = {
              uid: userCredential?.user?.uid,
            }            
            // @ts-ignore
            if (userCredential?._tokenResponse?.isNewUser) {
              setIsLoading(true); 
              createUser(data).then((result: any) => {    
                const userRef = doc(db, "users", userCredential?.user?.uid);

                const activeSessionId = uuidv4();
                localStorage.setItem("activeSessionId", activeSessionId)  
                setDoc(userRef, {activeSessionId}, { merge: true });         
                setIsLoading(false);                  
                if(result?.data?.status == false){
                  // @ts-ignore
                  showToast(result?.data?.message, ToastType.ERROR)                    
                }                              
              }).catch((err: any) => {                               
                setIsLoading(false);
                showToast(err.message, ToastType.ERROR);  
              });
            }
          })
          .catch((error: any) => {
            setIsLoading(false);            
            showToast(error.message, ToastType.ERROR);             
          });
      }
      }
  }
 
    
  return (
    <>
    {isLoading && <>
    <WaitForResponse/>
    </>}
    <div className="text-center" style={{ width: "300px" }}>     
      {loginRedirectMessage && (
        <H1 className=".tooltip-inner">
          You need to login to {loginRedirectMessage}.
        </H1>
      )}
      {!isInstagramAvailable &&
        !withLoginV2e &&
        !isTelegram &&
        Object.values(LoginProviders).map((provider, i) => {
          return (
            <div key={i} className="mb-2 w-100" id="login">
              <LoginWith
                provider={provider}
                onClick={() =>
                  // @ts-ignore
                  authProvider(
                    setUser,
                    providers[provider],
                    showToast,
                    // @ts-ignore
                    setSmsVerification,
                    () => {
                      setLogin(false);
                    }
                  )
                }
              />
            </div>
          );
        })}      
      {(isInstagramAvailable || isTelegram )&& (
        <div>
          <img src={googleLogo} alt="" style={{ width: "50px" }} />
          <div style={{ width: "260px", color: "black", margin: "20px" }}>
            {/* To login with google, copy this link and open in another browser,{" "} */}
            {withLoginV2e ? 
              "If you have used the Google signup option to create your V2E account, copy this link and open in another browser"
              : 
              "To login with google, copy this link and open in another browser"
            } {" "}
            <span
              style={{
                textAlign: "center",
                color: "var(--blue-violet)",
                fontSize: "9px",
              }}
              onClick={() => {
                copy(window.location.href);
                showToast(
                  "Your link is copied to the clipboard.",
                  ToastType.SUCCESS
                );
              }}
            >
              Copy link
            </span>
          </div>
        </div>
      )}
      {/* {!withLoginV2e && isTelegram && 
        <div className="telegramLoginButton">
        <LoginButton
          botUsername={'coinptestBot'}
          cornerRadius={5}
          showAvatar={false}
            onAuthCallback={async (response: any) => {              
            console.log('telegram', response);
            //  Send Telegram response to server for validation
            const data = await telegramLoginFuncation(response);
            console.log(data.data, "dataresponse")
            // @ts-ignore                                       
            const auth = getAuth();
            console.log(auth,"getauth")
            // @ts-ignore
            signInWithCustomToken(auth, data?.data?.result)
              .then((userCredential: any) => {
                console.log(userCredential, "userCredential")
                // User is signed in                         
                const data = {
                  uid: userCredential?.user?.uid,
                }
                // @ts-ignore
                if (userCredential?._tokenResponse?.isNewUser) {
                  createUser(data).then((result: any) => {
                    console.log(result, "telegram:6687516119")
                  }).catch((err: any) => {
                    console.log(err)
                  });
                }
              })
              .catch((error: any) => {
                // Handle error
                console.error(error);
              });
          }}
        />
        </div>
      } */}
      {!withLoginV2e && <div className="mb-2 w-100 ">
        <LoginButton2
          style={{
            boxShadow: window.screen.width > 979 ? "0px 3px 6px #00000029" : "",
            background:"white",
          }}
          onClick={() => {
            LoginWithTelegram()
          }}
        >         

          {!withLoginV2e &&  isTelegram && (
            <div
              className="d-flex py-1 justify-content-start align-items-center"
              style={{
                marginLeft: "25px",
              }}
            >
              <Image
                src={telegram}
                alt=""
                width={"35px"}
                height={"35px"}
                className="pl-3"
              />
              <ContinueWith>Continue with Telegram</ContinueWith>
            </div>
          )}
        </LoginButton2>
      </div>}

      <div className="mb-2 w-100">
        <LoginButton2
          style={{
            boxShadow: window.screen.width > 979 ? "0px 3px 6px #00000029" : "",
          }}
          onClick={() => {
            setWithLoginV2e(!withLoginV2e);
          }}
        >         

          {!withLoginV2e && (
            <div
              className="d-flex py-2 justify-content-start align-items-center"
              style={{
                marginLeft: "25px",
              }}
            >
              <Image
                src={v2elogo}
                alt=""
                width={"30px"}
                height={"30px"}
                className="pl-3"
              />
              <ContinueWith>Continue with VoteToEarn</ContinueWith>
            </div>
          )}
        </LoginButton2>
      </div>

      {!withLoginV2e && (
        <div className="my-3 align-self-center">
          <OR className="mx-auto">{translate("or")}</OR>
        </div>
      )}
      <div className="mb-3 w-100">
        <LoginForm
          callback={{
            successFunc: (params) => {
              setLoader(true);
              setUser(params);
            },
            errorFunc: (e) => showToast(e.message, ToastType.ERROR),
          }}
          login={login}
        />
      </div>

      {withLoginV2e ? (
        <ForgetPasswordText
          className="d-flex justify-content-center align-items-center cursor-pointer"
          onClick={() => setWithLoginV2e(false)}
        >
          <span
            className="material-symbols-outlined"
            style={{ fontSize: "17px" }}
          >
            arrow_back
          </span>
          Go back
        </ForgetPasswordText>
      ) : (
        <>
          <div className="d-flex justify-content-center">
            <ForgetPasswordText
              onClick={() => setForgetPassword(true)}
            >{`${translate(
              texts.ForgetPassword.toUpperCase()
            )}`}</ForgetPasswordText>
          </div>
          <div className="d-flex justify-content-center mt-2">
            <DontHaveAccountText className="mr-5">
              {" "}
              {`${translate(texts.noAccount)} `}
            </DontHaveAccountText>
            <SignUp onClick={() => setSignup(true)}>{`${translate(
              texts.signUp.toUpperCase()
            )}`}</SignUp>
          </div>
        </>
      )}
      <div id="loginId"></div>
      <Modal
        show={smsVerification ? true : false}
        onHide={handleClose}
        style={{
          top: "25%",
          maxWidth: window.screen.width < 979 ? "100vw" : "",
        }}
      >
        <Modal.Header>
          <Modal.Title>2FA</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Please enter verification code which is sent to your number.</p>
          <FormControl
            className="mt-2"
            type="number"
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
          />
        </Modal.Body>

        <Modal.Footer>
          <Buttons.Default onClick={handleClose}>Close</Buttons.Default>
          <Buttons.Primary
            // disabled={!valid}
            onClick={async () => {
              // @ts-ignore
              const cred = PhoneAuthProvider.credential(
                // @ts-ignore
                smsVerification?.verificationId,
                verificationCode
              );
              const multiFactorAssertion =
                PhoneMultiFactorGenerator.assertion(cred);
              // Complete sign-in.
              // @ts-ignore
              return smsVerification?.resolver.resolveSignIn(
                multiFactorAssertion
              );
            }}
          >
            CONTINUE
          </Buttons.Primary>
        </Modal.Footer>
      </Modal>
    </div>
    </>
  );
};

export default Login;
