
import React, { useContext, useEffect, useReducer, useState } from 'react';
import './DottedProgressBar.css';
import bull from '../../assets/svg/bull.svg';
import bear from '../../assets/svg/bear.svg';
import { Totals } from 'Contexts/CoinsContext';
import styled from 'styled-components';
import BullBearButton from 'Components/Users/Voteimpactbar/BullBearButton';
// import { decimal } from "../../Profile/utils";
const Container = styled.div`
max-width: ${window.screen.width < 767 ? "345px" : "400px"};
margin:auto;
  box-shadow: 0 3px 6px #00000029;
  border-radius: 6px;
  opacity: 1;
  width: 100%;
  padding: 16px 10px;
`;

const YourVote = styled.div`
  font: var(--font-style-normal) normal var(--font-weight-normal)
    var(--font-size-18) / 23px var(--font-family-poppins);
    padding: 0px 0px 8px 0px;
  letter-spacing: var(--character-spacing-0);
  color: var(--color-160133);
  opacity: 1;
  text-align: center;
  text-transform: capitalize;  
`;
const CPVI = styled.div`
  font: var(--font-style-normal) normal var(--font-weight-normal)
    var(--font-size-17) / var(--line-spacing-10) var(--font-family-poppins);
  letter-spacing: var(--character-spacing-0);
  color: var(--color-ffffff);
  text-align: left;
  opacity: 1;
  line-height: 24px;
`;

const Votes = styled.div`
  font: var(--font-style-normal) normal var(--font-weight-normal)
    var(--font-size-12) / var(--line-spacing-10) var(--font-family-poppins);
  letter-spacing: var(--character-spacing-0);
  color: var(--color-ffffff);
  text-align: left;
  opacity: 1;
  line-height: 24px;
`;

export type ProgressProps = {
    totals: { [key: string]: Totals };
    progressData?: {
        success: number
        total: number
    }
    symbol1: string;
    symbol2:string;
    pct?: any;
    pct2?: any;
    compare?: boolean;
    isSingleCoinVote?: boolean;
};



const DottedProgressBar = ({ totals, progressData, symbol1, symbol2, pct=0,pct2=0, compare = true, isSingleCoinVote = false }: ProgressProps) => {        
    const totalBoxes = 10;       
    const [widthValue, setWidthValue] = useState(0);
    const [widthValue2, setWidthValue2] = useState(0); 
    const [showTooltip, setShowTooltip] = useState(false);
    const [borderColorLeft, setBorderColorLeft] = useState(["#2c2796","#433ba9","#433ba9","#6458c5","#6f60ce","#7e6dda","#7e6dda","#7e6dda","#7e6dda","#7e6dda","#7e6dda"]);    
    const [borderColorRight, setBorderColorRight] = useState(["#7e6dda", "#7e6dda", "#7e6dda", "#7e6dda", "#7e6dda", "#7e6dda","#6f60ce", "#6458c5", "#433ba9", "#433ba9", "#2c2796"]);   

    console.log(symbol2,"symbol2symbol2")
    return (

        <Container>
            <div>
                {
                    showTooltip &&
                    <div
                        style={{
                            display: "relative",
                        }}
                    >
                        <div className="newtooltip2"
                            style={{
                                maxWidth: `${window.screen.width > 767 ? "26%" : "90%"}`,
                                marginTop: `${window.screen.width > 767 ? "-13em" : "-13em"}`,
                                textAlign: 'justify',
                                marginRight: '2em'
                            }}
                        >
                            {isSingleCoinVote ?
                                <p style={{
                                    lineHeight: "2"
                                }}>
                                    {/* The SVI aggregates voting profiles using a weighted algorithm that considers factors like volume, time frame, and success rate. It represents this data as a line graph ranging from 0 to 100. An SVI reading above 50 suggests that more users are optimistic about the coin's potential to increase in value (BULL). Conversely, a reading below 50 indicates a more pessimistic sentiment <span>{"(BEAR)"}</span>. */}
                                    The Social Voting Indicator (SVI) shows the voting momentum of Coin Parliament members over the last 7 days, reflecting the community’s voting activity and trends.
                                </p> :
                                <p style={{
                                    lineHeight: "2"
                                }}>
                                    The Social Voting Indicator (SVI) shows the voting momentum of Coin Parliament members over the last 7 days, reflecting the community’s voting activity and trends.
                                </p>
                                }
                        </div>
                    </div>
                }
                <div className="justify-content-between align-content-between d-flex mb-3">
                    <CPVI style={{
                        cursor: 'pointer',
                        color:"#6352e9"
                        
                    }}
                        onMouseEnter={() => setShowTooltip(true)}
                        onMouseLeave={() => setShowTooltip(false)}
                        onClick={() => setShowTooltip((prev) => !prev)}
                    >
                        SVI (i)
                    </CPVI>
                    <Votes></Votes>
                </div>
            </div>
            <YourVote>
                <p>Members voted</p>
            </YourVote>
        <div className="containerValueDot">
            <div className='FullbarimpactDot'>                
                <div className="boxesdot"
                        style={{
                        left:"10px",                        
                        borderRadius: "10px 0px 0px 10px",
                    }}
                >
                    <div className='backGroundBoxDot'>
                        {Array.from({ length: totalBoxes }).map((_, index) => {
                            return (
                                <div key={index} className="boxchilddot"
                                style={{
                                    marginRight: `${index === totalBoxes - 1 ? "6px":"0px"}`,
                                    borderRadius: `${index === 0 ? "10px 0px 0px 10px" : "50% 0% 0% 50%"}`,                                    
                                    borderRight:"none",
                                    borderTop:"none",
                                    borderBottom:"none",                                    
                                }}
                                ></div>
                            );
                        })}
                        <div className='whiteBox'
                            style={{                                
                                width: `${pct != undefined ? 100 - pct : 100}%`,
                                transition: "width 0.8s ease-in-out",
                            }}
                        >
                             <div className='shap-boxdot'>                            
                            <div className='state-div'>

                            </div>
                            <div className='round-divdot'
                            style={{
                                width: `${100 - pct < 95? "5px":"0px"}`,
                                backgroundColor:`${borderColorRight[Math.round((100 - pct)/10)]}`,
                            }}
                            >

                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            
                <div className="centerBoxdot">
                       
                        <BullBearButton
                            symbol1={symbol1}
                            symbol2={symbol2}
                        />
                </div>

                <div className="boxesdot"
                        style={{
                        right: "10px",
                        borderRadius: `0px 10px 10px 0px`,
                    }}
                >
                    <div className='backGroundBoxDot2'>
                        {Array.from({ length: totalBoxes }).map((_, index) => {
                            return (
                                <div key={index} className="boxchilddot"
                                style={{  
                                    marginLeft: `${index == 0 ? "6px":"0px"}`,                                  
                                    borderRadius: `${index === totalBoxes - 1 ? "0px 10px 10px 0px" : "0% 50% 50% 0%"}`, 
                                    borderLeft:"none",
                                    borderTop:"none",
                                    borderBottom:"none",
                                }}
                                ></div>
                            );
                        })}
                        <div className='whiteBox2'
                            style={{
                                width: `${pct != undefined ? pct :100}%`,                                
                                transition: "width 0.8s ease-in-out",
                            }}
                        >
                            <div className='shap-boxdot2'>
                            <div className='round-div2'
                            style={{
                                width: `${pct < 95? "5px":"0px"}`,
                                backgroundColor:`${borderColorRight[Math.round((pct)/10)]}`,
                            }}
                            >

                            </div>
                            <div className='state-div2'>

                            </div>
                            </div>
                        </div>
                    </div>
                    </div>                    
            </div>
            {/* <div className='FullbarimpactDot'>
            
                <div className="boxesdot"
                        style={{
                        left:"10px",                        
                        borderRadius: "10px 0px 0px 10px",
                    }}
                >
                    <div className='backGroundBoxDot'>
                        {Array.from({ length: totalBoxes }).map((_, index) => {
                            return (
                                <div key={index} className="boxchilddot"
                                    style={{
                                        marginRight: `${index === totalBoxes - 1 ? "6px":"0px"}`,
                                        borderRadius: `${index === 0 ? "10px 0px 0px 10px" : "0px"}`,                                        
                                    }}
                                ></div>
                            );
                        })}
                        <div className='whiteBox'
                            style={{
                                
                                width: `${pct ==0 && pct2==0 ? 50 : pct ? 100 - pct : 100}%`,
                                transition: "width 0.8s ease-in-out",
                            }}
                        >
                        </div>
                    </div>
                </div>

                
                <div className="centerBoxdot">                     
                        <BullBearButton
                            symbol1={symbol1}
                            symbol2={symbol2}
                        />
                </div>

                
                <div className="boxesdot"
                        style={{
                        right: "10px",
                        borderRadius: `0px 10px 10px 0px`,
                    }}
                >
                    <div className='backGroundBoxDot2'>
                        {Array.from({ length: totalBoxes }).map((_, index) => {
                            return (
                                <div key={index} className="boxchilddot"
                                    style={{
                                        marginLeft: `${index == 0 ? "6px":"0px"}`,  
                                        borderRadius: `${index === totalBoxes - 1 ? "0px 10px 10px 0px" : "0px"}`,
                                    }}
                                ></div>
                            );
                        })}
                        <div className='whiteBox2'
                            style={{
                                width: `${pct ==0 && pct2==0 ? 50 :pct2 ? 100-pct2 :100}%`,
                                transition: "width 0.8s ease-in-out",
                            }}
                        >
                        </div>
                    </div>
                    </div>                    
            </div> */}
            </div>
            {pct >=0 && <div className='SVItext'>
                <p>{pct ==0 && pct2==0 ?"50":Number(pct).toFixed(2)}%</p>
                <p>{pct ==0 && pct2==0 ?"50":Number(pct2).toFixed(2)}%</p>
            </div>}
        </Container>
    );
};

export default DottedProgressBar;